<template>
  <img dir="small" class="demo-bg" src="../assets/launch.webp" alt="" style="height: 100%" />

  <div class="container timer demo-content" style="color: #17013d">
    <div class="row h-100">
      <div
        class="col align-self-center countDownSection p-5"
        style="width: max-content"
      >
        <h1 id="headline">Site Will be available in</h1>
        <div id="countdown">
          <ul>
            <li><span id="days"></span>days</li>
            <li><span @click="changePage()" id="hours"></span>Hours</li>
            <li><span id="minutes"></span>Minutes</li>
            <li><span id="seconds"></span>Seconds</li>
          </ul>
        </div>
        <h5>All Rights Reserved. Copyright &copy; SPNM</h5>
        <div id="content" class="emoji">
          <!-- <span>🥳</span>
          <span>🎉</span>
          <span>🎂</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timer",

  data() {
    return {};
  },

  mounted() {
    (function () {
      if (document.getElementById("days")) {
        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24;

        //I'm adding this section so I don't have to keep updating this pen every year :-)
        //remove this if you don't need it
        let today = new Date(),
          dd = String(today.getDate()).padStart(2, "0"),
          mm = String(today.getMonth() + 1).padStart(2, "0"),
          yyyy = today.getFullYear(),
          nextYear = yyyy + 1,
          dayMonth = "04/30/2022",
          birthday = dayMonth;

        today = mm + "/" + dd + "/" + yyyy;
        if (today > birthday) {
          birthday = dayMonth + nextYear;
        }
        //end

        //   birthday = "04/28/2022"

        const countDown = new Date(birthday).getTime(),
          x = setInterval(function () {
            const now = new Date().getTime(),
              distance = countDown - now;

            if (document.getElementById("days")) {
              (document.getElementById("days").innerText = Math.floor(
                distance / day
              )),
                (document.getElementById("hours").innerText = Math.floor(
                  (distance % day) / hour
                )),
                (document.getElementById("minutes").innerText = Math.floor(
                  (distance % hour) / minute
                )),
                (document.getElementById("seconds").innerText = Math.floor(
                  (distance % minute) / second
                ));
            }
    
            //do something later when date is reached
            // if (distance < 0) {
            //   document.getElementById("headline").innerText = "It's my birthday!";
            //   document.getElementById("countdown").style.display = "none";
            //   document.getElementById("content").style.display = "block";
            //   clearInterval(x);
            // }
            //seconds
          }, 0);
      }
    })();
  },

  methods: {
    changePage() {
      this.$emit("onChange", "clicked");
    },
  },
};
</script>

<style scoped>
/* general styling */
:root {
  --smaller: 0.75;
}

.countDownSection {
  background-color: rgb(255, 255, 255, 0.5);
}

.timer {
  text-align: center;
  height: 100%;
  color: rgb(88, 88, 88);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
}

.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.demo-content {
  position: relative;
}

body {
  align-items: center;

  /* background-color: #ffd54f; */
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.container {
  color: #333;
  margin: 0 auto;
  text-align: center;
}

h1 {
  font-weight: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

li span {
  display: block;
  font-size: 4.5rem;
}

.emoji {
  display: none;
  padding: 1rem;
}

.emoji span {
  font-size: 4rem;
  padding: 0 0.5rem;
}

@media all and (max-width: 768px) {
  h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  li {
    font-size: calc(1.125rem * var(--smaller));
  }

  li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}
</style>