<template>
  <footer class="">
    <!-- <div class="row mb-2 p-2" id="FooterLevelOne">
      <div class="col-md-4">
        <p style="margin-bottom: 0px !important">SUBSCRIBE TO OUR</p>
        <h5>N E W S L E T T E R</h5>
      </div>
      <div class="col-md-8">
        <div class="inputButtonArea p-1 mt-2">
          <div class="row">
            <div class="col-8">

            </div>        
            <div class="col text-left">
             
              </div>   
            <div class="col text-right">
              <button id="sbButton" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div id="mainFooter" class="mt-5">
      <div class="row mt-4">
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Our Network</span>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contributors-organisations"
                >Contributing Organisations</router-link
              >
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/contributors-partners"
                >Partners</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/affiliates"
                >Other Groups</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Help</span>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://merchant.razorpay.com/policy/KtlqpJmG5WwrYq/contact_us">
                Contact Us
              </a>
            </li>
            
            <li class="nav-item">
              <a target="_blank" href="https://merchant.razorpay.com/policy/KtlqpJmG5WwrYq/terms">
                Terms & Conditions
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://merchant.razorpay.com/policy/KtlqpJmG5WwrYq/refund">
                Cancellation & Refund Policy
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://merchant.razorpay.com/policy/KtlqpJmG5WwrYq/privacy">
                Privacy Policy
              </a>
            </li>
            <li class="nav-item">
              <a target="_blank" href="https://merchant.razorpay.com/policy/KtlqpJmG5WwrYq/shipping">
                Shipping & Delivery Policy
              </a>
            </li>

            
          </ul>
        </div>
        <div class="col-md-4 footer-column">
          <ul class="nav flex-column">
            <li class="nav-item">
              <span class="footer-title">Contact & Support</span>
            </li>
            <li class="nav-item">
              <span class="nav-link"
                ><i class="fas fa-phone"></i>+91-9702023276 / 9773823276
              </span>
            </li>
            <li class="nav-item">
              <span class="nav-link"
                ><i class="fas fa-tty"></i>+91-22-66098010 / 66098011</span
              >
            </li>
            <li class="nav-item">
              <span class="nav-link"
                ><i class="fas fa-envelope"></i
                >mumbai@spnm.in</span
              >
            </li>

            <li class="nav-item">
              <span class="nav-link"
                ><i class="fas fa-address-book"></i>The Shipping Professional
                Network in Mumbai <br />
                <span class="ml-4">C/o Mantrana Maritime Advisory </span> <br />
                <span class="ml-4">A-207/8, Sai Chambers,</span> <br />
                <span class="ml-4">Plot 44, Sector 11,</span> <br />
                <span class="ml-4">CBD Belapur, Navi Mumbai – 400614</span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="text-center"><i class="fas fa-ellipsis-h p-0"></i></div>

      <div class="row text-center p-3" id="FooterLevelThree">
        <div class="col-md-4 box">
          <span class="copyright quick-links">Copyright &copy; SPNM </span>
        </div>
        <div class="col-md-4 box">
          <ul class="list-inline social-buttons">
            <li class="list-inline-item">
              <a target="_blank" href="https://twitter.com/SPNM19">
                <i class="fab fa-twitter"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/the-shipping-professional-network-in-mumbai/?viewAsMember=true"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-4 box">
          <ul class="list-inline quick-links">
            <li class="list-inline-item">
              <span>Designed & Developed By</span>
            </li>
            <li class="list-inline-item">
              <a
                style="color: #3b5aa7 !important"
                target="_blank"
                href="https://www.essenzaasolutions.com/"
                >Essenzaa</a
              >
            </li>
          </ul>
        </div>
      </div>

      
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSections",
};
</script>

<style>
a {
  color: #6c757d;
}
a:hover {
  color: #3b5aa7;
  text-decoration: none;
}
::selection {
  background: #3b5aa7;
  text-shadow: none;
}
footer {
  padding: 2rem 0;
  background-color: #fff;
}

#mainFooter {
  border-top: 2px solid #3b5aa7;
}
.footer-column:not(:first-child) {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .footer-column:not(:first-child) {
    padding-top: 0rem;
  }
}
.footer-column {
  text-align: center;
}
.footer-column .nav-item .nav-link {
  padding: 0.1rem 0;
}
.footer-column .nav-item span.nav-link {
  color: #6c757d;
}
.footer-column .nav-item span.footer-title {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  text-transform: uppercase;
}
.footer-column .nav-item .fas {
  margin-right: 0.5rem;
}
.footer-column ul {
  display: inline-block;
}
@media (min-width: 768px) {
  .footer-column ul {
    text-align: left;
  }
}
ul.social-buttons {
  margin-bottom: 0;
}
ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #3b5aa7;
}
ul.social-buttons li a {
  font-size: 15px;
  line-height: 30px;
  display: block;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
  border-radius: 100%;
  outline: 0;
  background-color: #1a1d20;
}
footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.copyright {
  color: #212529;
}
.fa-ellipsis-h {
  color: #212529;
  padding: 2rem 0;
}
/* #FooterLevelThree {
  background-color: #ececec;
} */

#FooterLevelOne {
  background-color: #ececec;
}

.inputButtonArea {
  background-color: #fff;
  border-radius: 50px;
}
#sbButton {
  border-radius: 50px;
}
#FooterLevelThree {
  margin: opx !important;
}
</style>
