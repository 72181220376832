<template>
  <div class="payment-success p-5">
    <div class="row justify-content-center">
      <img dir="small"
        src="../../assets/success/success.webp"
        height="100"
        width="100"
        alt=""
      />
    </div>
    <div class="row justify-content-center mt-3">
      <p class="text-secondary">Payment Successfull !</p>
    </div>
    <div class="row justify-content-center mt-1">
      <p class="text-secondary">
        Payment ID: {{ transactionDetails.paymentId }}
      </p>
    </div>
    <div class="row justify-content-center mt-1">
      <b
        ><p class="text-secondary">
          {{ transactionDetails.acknowledgementNumber }}
        </p></b
      >
    </div>

    <div class="row justify-content-center mt-1">
      <p class="text-secondary">
        {{ transactionDetails.message }}
      </p>
    </div>

    <div class="row justify-content-center mt-1">
      <button data-dismiss="modal" class="btn btn-spnm btn-sm">
        Alright<i class="fas fa-thumbs-up fa-sm ml-2"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess",
  props: ["referenceData"],
  data() {
    return {
      transactionDetails: {
        paymentId: "NA",
        acknowledgementNumber: "NA",
        message:
          "Registered Successfully. For more info please check your mail.",
      },
    };
  },
  watch: {
    referenceData: function (newVal, OldVal) {
      this.transactionDetails = newVal;
    },
  },
  created() {

    if (this.referenceData) {
      this.transactionDetails = this.referenceData;
    }
  },
};
</script>

<style>
</style>