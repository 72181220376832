<template>
  <!-- Modal -->
  <div
    class="modal fade"
  
    :id="modalId"
    tabindex="-1"
    :aria-labelledby="'registrations_' + modalId"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog
        modal-dialog-centered
        modal-dialog-scrollable
        modal-xl
      "
      style="height:fit-content !important"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">User List</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <CoreDatatable
            v-if="tableData.tableHeaders != null"
            :dataBase="tableData.dataBase"
            :tableHeaders="tableData.tableHeaders"
            :title="'User List'"
            :dynamicTableId="dataTableID"
            @onTableReady="enableDatatableFeatures()"
          />

          <div class="row justify-content-center">
            <button
              type="button"
              @click="sendMail"
              class="btn btn-spnm btn-sm"
              :disabled="mailToSend.length == 0"
            >
              Send Invitation
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CoreDatatable from "../Core/Datatable.vue";
import $ from "jquery";
import axios from "axios";

var table;
var databaseLength = 0;

export default {
  name: "EventInviteList",
  props: ["eventInfo", "modalId"],
  components: {
    CoreDatatable,
  },
  data() {
    return {
      dataTableID: "UserListTable",
      mailToSend: [],
      tableData: {
        dataBase: [],
        tableHeaders: null,
        title: "User List",
      },
    };
  },
  created() {
    this.getDataBase();
    this.dataTableID = "UserListTable"+this.eventInfo.eventId
  },
  mounted() {},
  methods: {
    getDataBase() {
      var requestBody = {};
      var _this = this;

      axios
        .post("/api/User/UserList", requestBody)
        .then(function (response) {
          databaseLength = response.data.length;
          _this.generateTable(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
    generateTable(dataBase) {
      var tableHeaders = [
        {
          title:
            '<input type="checkbox" class=" mt-0 ml-0 invite-select-all" id="inviteSelectAll"> ',
          data: "userId",
          className: "dt-left",
          orderable: false,
          render: function (data, type) {
            return '<input type="checkbox" class="form-check-input ml-1 select-checkBox" id="selectCheckbox">';
          },
        },
        {
          title: "Membership No.",
          data: "membershipNo",
          className: "dt-left",
          render: function (data, type) {
            return "" + data;
          },
        },
        {
          title: "Membership Status",
          data: "membershipStatus",
          className: "dt-center",
          render: function (data, type) {
            if (data == "Active") {
              return '<span class="badge badge-success text-center">Active</span>';
            } else {
              return '<span class="badge badge-danger text-center">InActive</span>';
            }
          },
        },
        {
          title: "First Name",
          className: "dt-left",
          data: "firstName",
        },
        {
          title: "Last Name",
          className: "dt-left",
          data: "lastName",
        },
        {
          title: "Company Name",
          className: "dt-left",
          data: "companyName",
        },
        {
          title: "Job Title",
          className: "dt-left",
          data: "jobTitle",
        },
        {
          title: "Personal Mail ID",
          className: "dt-left",
          data: "personalEmailId",
        },
        {
          title: "Contact Number",
          className: "dt-left",
          data: "personalMobileNo",
        },
      ];

      this.tableData.tableHeaders = tableHeaders;
      this.tableData.dataBase = dataBase;

      var _this = this;

      //   $("#" + _this.modalId + " tbody").on(
      //       "click",
      //       "#selectCheckbox",

      //       function () {
      //         var rowdata = table.row($(this).parents("tr")).data();
      //         console.log(rowdata)
      //       }
      //     );
    },

    enableDatatableFeatures() {
      table = $("#dashboardTable" + this.dataTableID).DataTable();
      var _this = this;
      $("#dashboardTable" + this.dataTableID + " tbody").on(
        "click",
        "input.select-checkBox",

        function () {
          var rowdata = table.row($(this).parents("tr")).data();
          let obj = _this.mailToSend.find((o) => o.userId === rowdata.userId);

          if (obj) {
            _this.mailToSend.splice(
              _this.mailToSend.findIndex((a) => a.userId === rowdata.userId),
              1
            );
          } else {
            _this.mailToSend.push(rowdata);
          }

          if (_this.mailToSend.length == databaseLength) {
            _this.mailToSend = _this.tableData.dataBase;
            $(".invite-select-all").prop("checked", true);
          } else {
            $(".invite-select-all").prop("checked", false);
          }
        }
      );

      $("#dashboardTable" + this.dataTableID + " thead").on(
        "click",
        "input.invite-select-all",

        function () {
          if (_this.mailToSend.length != parseInt(databaseLength)) {
            _this.mailToSend = _this.tableData.dataBase;
            $(".select-checkBox").prop("checked", true);
          } else {
            _this.mailToSend = [];
            $(".select-checkBox").prop("checked", false);
          }
        }
      );
    },

    sendMail() {
    
      var requestBody = {
        eventId:this.eventInfo.eventId,
        UserInformation: this.mailToSend,
      };

      var _this = this;

      axios
        .post("/api/Event/InviteForEvent", requestBody)
        .then(function (response) {
          alert(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>
  
  <style>
</style>