<template>
  <!-- Know More About Event Modal -->
  <div
      class="modal fade"
      :id="modalId"
      tabindex="-5"
      role="dialog"
      :aria-labelledby="'area_'+modalId"
      aria-hidden="true"

    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="eventModalLabel">
             {{eventInfo.title}} <small><i>("{{eventInfo.tagLine}}")</i></small>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body custom-scroller text-left" style="overflow-x: scroll">
            <div id="eventInfo">
              <p>
                {{eventInfo.description1}}
              </p>
              <p>
                {{eventInfo.description2}}
              </p>
              <p>
                {{eventInfo.description3}}
              </p>
              
            </div>
            <hr />
            <!-- <div>
              <div id="" class="row m-0 justify-content-center" >

                <div>
                  <h5>In Collaboration With</h5>
                <span class="borderSection"></span>
                </div>
                
              </div>
              <div class="row p-2">
                <div class="col" style="text-align:center">
                  <img dir="small"
                    src="../../assets/Contributors/everlloyed.webp"
                    alt=""
                    height="110"
                    width="120"
                  />
                </div>
                <div class="col" style="text-align:center">
                  <img dir="small"
                    src="../../assets/Contributors/nagarkot-logo.webp"
                    alt=""
                    height="110"
                    width="110"
                  />
                </div>
                <div class="col" style="text-align:center">
                  <img dir="small"
                    src="../../assets/Contributors/isa.webp"
                    alt=""
                    height="110"
                    width="150"
                  />
                </div>
              </div>
            </div>
            <hr /> -->
            <div id="eventDetails" class="custom-scroller">
              <h6>Event and Membership Registration details</h6>
              <table class="table table-striped">
                <tr>
                  <th>Venue:</th>
                  <td>
                    {{eventInfo.venue}}
                  </td>
                </tr>
                <tr>
                  <th>Time and date:</th>
                  <td>{{eventInfo.eventDate}}, {{eventInfo.eventTime}}</td>
                </tr>
                <tr>
                  <th>Dress code:</th>
                  <td>{{eventInfo.dressCode}}</td>
                </tr>

                <tr>
                  <th>Entry Fee For Existing members:</th>
                  <td> <i class="fas fa-indian-rupee-sign"></i>{{eventInfo.memberEntryFee}}</td>
                </tr>
                <tr>
                  <th>Entry Fee For Non-members:</th>
                  <td> <i class="fas fa-indian-rupee-sign"></i>{{eventInfo.nonMemberEntryFee}}</td>
                </tr>

                
                <tr>
                  <th>Please call:</th>
                  <td>{{eventInfo.contactPersonMobileNumber}}</td>
                </tr>
                <tr>
                  <th>Contact person:</th>
                  <td>{{eventInfo.contactPerson}}</td>
                </tr>
              </table>
            </div>
            <hr />
            <h6>Location Map</h6>
            <div id="locationMap">
              <iframe
                :src="eventInfo.mapLink"
                width="100%"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
         
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name:"KnowMore",
    props:["eventInfo","modalId"]
}
</script>

<style>

</style>