<template>
  <div id="user-details" class="p-2">
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>First Name:</b></div>
          <div class="col-8">{{ userInfo.firstName }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Last Name:</b></div>
          <div class="col-8">{{ userInfo.lastName }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Personal Email:</b></div>
          <div class="col-8">{{ userInfo.personalEmailId }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Official Email:</b></div>
          <div class="col-8">{{ userInfo.officialEmailId }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Gender:</b></div>
          <div class="col-8">{{ userInfo.gender }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Comapny Name:</b></div>
          <div class="col-8">{{ userInfo.companyName }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Job Title/Position:</b></div>
          <div class="col-8">{{ userInfo.jobTitle }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Mobile Number/s:</b></div>
          <div class="col-8">
            {{ userInfo.personalMobileNo }}/{{ userInfo.alternativeMobileNo }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">

      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Address:</b></div>
          <div class="col-8">{{ userInfo.address }}</div>
        </div>
      </div>
      
      <div class="col-md-6">
        <div class="row">
          <div class="col-4"><b>Industry Vertical:</b></div>
          <div class="col-8">{{ userInfo.industryVerticalName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDetails",
  props: ["UserInfo"],
  data() {
    return {
      userInfo: {},
    };
  },
  watch:{
    UserInfo:function () {
      this.userInfo = this.UserInfo;
      if (this.userInfo) {
      this.setIndustryVertical(this.userInfo.industryVertical);
    }
    }
  },

  computed: {
    watchIndustryVerticals() {
      return this.$store.getters.IndustryVerticals;
    },
  },
  
  created() {
    this.userInfo = this.UserInfo;
    if (this.userInfo) {
      this.setIndustryVertical(this.userInfo.industryVertical);
    }

  },
  mounted() {},

  methods: {
    setIndustryVertical() {
      if (this.industryVerticals != null) {
        var verticalId = this.userInfo.industryVertical;
        var newArray = this.industryVerticals.filter(function (el) {
          return el.gpId == verticalId;
        });

        if (newArray.length != 0) {
          this.userInfo.industryVerticalName = newArray[0].shortDescription;
        } else {
          this.userInfo.industryVerticalName =
            this.userInfo.otherIndustryVertical;
        }
      }
      console.log(this.userInfo)
    },
  },
};
</script>

<style>
</style>