<template>
  <div class="container unsubscribed">
    <div class="row h-100">
      <div class="col align-self-center p-2">
        <div class="log-area">
          <img
            src="../../assets/Logo/SPNM.png"
            alt=""
            height="200"
            width="350"
          />
        </div>
        <div class="card p-5 shadow bg-white mt-5 message-area">
          <h1 v-if="message1 != null">{{ message1 }}</h1>
          <h1 v-if="message1 == null">Please wait...</h1>
          <h5 v-if="message2 != null">{{ message2 }}</h5>
        </div>
        <div class="subscribe-area mt-5 text-secondary">
          <h6 v-if="unSubscribe">
            Unsubscribe by accident?
            <a class="text-primary" :href="'/Subscribed?refId='+rfId">Re-Subscribe</a>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UnsubscribeToMail",
  props: [],
  data() {
    return {
      message1: null,
      message2: null,
      unSubscribe: false,
      subscribe: false,
      rfId: 0,
    };
  },
  created() {
    var urlString = window.location.href.toString();
    if (urlString.indexOf("Unsubscribed") > -1) {
      var url = new URL(urlString);
      this.rfId = url.searchParams.get("refId");
      this.unSubscribe = true;
      this.unSubscribeToMailing(this.rfId);
    } else if (urlString.indexOf("Subscribed") > -1) {
      var url = new URL(urlString);
      this.rfId = url.searchParams.get("refId");
      this.subscribe = true;
      this.subscribeToMailing(this.rfId);
    }
  },
  mounted() {},
  methods: {
    unSubscribeToMailing(refId) {
      var _this = this;
      var requestBody = {
        MailerListId: parseInt(refId),
      };
      axios
        .post("/api/MailingListSource/Unsubscribe", requestBody, {
          withCredentials: true,
        })
        .then(function (resp) {
          _this.message1 = "You've been unsubscribed.";
          _this.message2 = "We won't send you any more emails.";
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
          } else {
            console.log(error);
          }
        });
    },
    subscribeToMailing(refId) {
      var _this = this;
      var requestBody = {
        MailerListId: parseInt(refId),
      };
      axios
        .post("/api/MailingListSource/Subscribe", requestBody, {
          withCredentials: true,
        })
        .then(function (resp) {
          _this.message1 = "You've been subscribed.";
          _this.message2 = "Thank you for connecting.";
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style>
.unsubscribed .message-area {
  border-top: 5px solid #3b5aa7;
  border-radius: 1rem;
}

.unsubscribed {
  height: 90vh;
}
</style>