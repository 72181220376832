<template>
  <!-- <Timer v-if="timer" @onChange="switchtoLaunchPage()" />
  <LaunchPage @onChange="switchtoSite()" v-if="launch" /> -->
  <div>
    <div v-if="!isAdmin && !isEmailSubscription">
      <Core />
    </div>
    <div class="adminView" v-if="isAdmin && !isEmailSubscription">
      <AdminPanel />
      <ScreenLoadingVue/>
    </div>
    <UnsubscribeToMailVue v-if="isEmailSubscription && !isAdmin"/>
  </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";
import Core from "../src/views/Core.vue";
import Timer from "./LaunchComponents/Timer.vue";
import LaunchPage from "./LaunchComponents/LaunchPage.vue";
import AdminPanel from "./views/Admin.vue";

import axios from "axios";
import moment from "moment";
import ScreenLoadingVue from './components/Core/ScreenLoading.vue';
import UnsubscribeToMailVue from './components/Core/UnsubscribeToMail.vue';

export default {
  components: {
    Core,
    Timer,
    LaunchPage,
    AdminPanel,
    ScreenLoadingVue,
    UnsubscribeToMailVue
  },
  data() {
    return {
      timer: true,
      launch: false,
      mainsite: false,
      isAdmin: false,
      isEmailSubscription:false
    };
  },
  created() {
    var urlString = window.location.href.toString();

    if (urlString.indexOf("Unsubscribed") > -1 || urlString.indexOf("Subscribed") > -1) {
        this.isEmailSubscription = true;
    }
    else{
      this.checkAdmin();
    }
  },
  methods: {
    checkAdmin() {
      var _this = this;
      const emptyObj = {};
      axios
        .post(window.location.origin + "/api/Auth/IsAdmin", emptyObj, {
          withCredentials: true,
        })
        .then(function (resp) {
          _this.isAdmin = true;
          _this.$store.commit('setIsAdmin',true);

        })
        .catch(function (error) {
          _this.isAdmin = false;
          if (error.response) {
            console.log(error.response.data);
          } else {
            console.log(error);
          }
        });
    },

    switchtoLaunchPage() {
      this.timer = false;
      this.launch = true;
    },
    switchtoSite() {
      this.timer = false;
      this.launch = false;
      this.mainsite = true;
    },
  },
};
</script>
<style>

@import "datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "@fortawesome/fontawesome-free/css/all.css";

html,
body {
  height: 100%;
  width: 100%;
}

b{
  font-weight: 600 !important;
}

#app {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 0.9rem !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-spnm {
  background-color: #3b5aa7;
  color: #fff;
}
li {
  margin-top: 4px;
}
.bg-spnm {
  background-color: #3b5aa7;
  color: #fff;
}
.row {
  margin-right: 0px !important;
}
.adminView {
  background-color: #ededed;
  max-height: 100vh;
  min-height: 100vh;
}

.custom-scroller {
  overflow: auto;
}
.custom-scroller::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: #95989b !important;
  border-radius: 0.8rem !important;
  width: 0.001rem !important;
}

.custom-scroller::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  border-radius: 2 0px !important;
}

.dataTable th {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-weight: normal;
}

table th ,table td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;

}

.overlay{
  background-color: rgba(255,255,255,.7) !important;
}

.modal{
  z-index: 2050;
}

.cursor-pointer{
  cursor: pointer;
}


/* Datatable  */

.dataTables_scrollBody {
  overflow: auto;
}
.dataTables_scrollBody::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.dataTables_scrollBody::-webkit-scrollbar-thumb {
  background-color: #95989b !important;
  border-radius: 0.8rem !important;
  width: 0.001rem !important;
}

.dataTables_scrollBody::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  border-radius: 2 0px !important;
}


.dataTables_wrapper ::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}
.dataTables_wrapper ::-webkit-scrollbar-thumb {
  background-color: #95989b !important;
  border-radius: 10px !important;
  width: 3px !important;
}
.dataTables_wrapper ::-webkit-scrollbar-track {
  background-color: #f5f5f5 !important;
  border-radius: 2 0px !important;
}

table th,td{
  white-space: nowrap;
}

</style>
