<template>
  <div v-if="loading" class="overlay dark">
    <h5 class="text-secondary" style="font-size: 3rem;"><i class="fas fa-sync fa-spin"></i></h5>
  </div>
</template>

<script>
export default {
  name: "ScreenLoading",
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    watchLoadingCounter() {
      if(this.$store.getters.LoadingCounter==0){
        this.loading = false;
      }
      else{
        this.loading = true;
      }
    },
  },

  computed: {
    watchLoadingCounter() {
      return this.$store.getters.LoadingCounter;
    },
  },

  created(){
    if(this.$store.getters.LoadingCounter==0){
        this.loading = false;
      }
      else{
        this.loading = true;
      }
  }
};
</script>

<style>
.app-section .overlay,
.card .overlay,
.info-box .overlay,
.overlay-wrapper .overlay,
.small-box .overlay {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, .7);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2051 !important;
}


.card>.loading-img,
.app-section>.overlay .card>.overlay,
.info-box>.loading-img,
.info-box>.overlay,
.overlay-wrapper>.loading-img,
.overlay-wrapper>.overlay,
.small-box>.loading-img,
.small-box>.overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>