<template>
  <div>
    <div class="card mt-3" style="text-align: center">
      <div id="slideHeading" class="card-header ml-auto mr-auto mt-3 p-0">
        <h5>Partners</h5>
        
        <span class="borderSection"></span>
      </div>

      <div id="slideLogos" class="card-body p-0 row">
        <div class="col align-self-center">

        
        <div
          class="row mt-2 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              src="../../../assets/partners/ICS.jpeg"
              alt=""
              height="65"
              width="70%"
            />
          </div>
        </div>
        <div
          class="row mt-3 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              src="../../../assets/partners/MANTRANA.webp"
              alt=""
              height="110"
              width="60%"
            />
          </div>
        </div>
        <div
          class="row mt-3 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              src="../../../assets/partners/logo.webp"
              alt=""
              height="70"
              width="75%"
            />
          </div>
        </div>
        <div
          class="row mt-3 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              class="ml-2"
              src="../../../assets/partners/IPPTA.webp"
              alt=""
              height="90"
              width="60%"
            />
          </div>
        </div>
        
        <!-- <div
          class="row mt-3 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              src="../../../assets/partners/MARITIME.webp"
              alt=""
              height="70"
              width="50%"
            />
          </div>
        </div> -->
        <!-- <div
          class="row mt-3 p-1 ml-0 mr-0"
          style="width: 100%; text-align: center"
        >
          <div class="col ml-auto mr-auto">
            <img dir="small"
              src="../../../assets/partners/exim.webp"
              alt=""
              height="80"
              width="40%"
            />
          </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slideTwo",
};
</script>

<style>
@media only screen and (max-width: 600px) {
  #slideHeading {
    transform: scale(0.5);
  }
  #slideLogos {
    transform: scale(0.5);
  }
}
</style>
