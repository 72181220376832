<template>
  <div class="sidebar" style="width: 100%; height: 100%">
    <div
      class="row d-flex align-items-center justify-content-center"
      style="height: 100%"
    >
      <div class="col-1" id="sidebarDivider">
        <div class="verticalBar align-self-center"></div>
      </div>

      <div class="col h-100">
        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-ride="carousel"
          data-interval="8000"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" style="height: 100%">
              <slideThree />
            </div> 
            <div class="carousel-item " style="height: 100%">
              <slideFour />
            </div> 
             <div class="carousel-item ">
              <slideTwo />
            </div>
            <div class="carousel-item " style="height: 100%">
          <slideOne />
        </div>
           
          </div>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
import slideOne from "../Sidebar/slideOne.vue";
import slideTwo from "../Sidebar/slideTwo.vue";
import slideThree from "../Sidebar/slideThree.vue";
import slideFour from "../Sidebar/slideFour.vue";
export default {
  name: "Sidebar",
  components: {
    slideOne,
    slideTwo,
    slideThree,
    slideFour
  },
  mounted() {
    // $(".active").not('.carousel-item .active').removeClass("active");
    //     $('.carousel').carousel({
    //   interval: false,
    // });
  },
};
</script>

<style>
.sidebar {
  height: 100%;
  max-width: 20rem;
  position: fixed;
  z-index: -1;

  right: 0;

  overflow-x: hidden;
}

.carousel-inner {
  min-height: 90vh !important;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    height: 100%;
    max-width: 6rem;
    position: fixed;
    z-index: -1;

    right: 0;

    overflow-x: hidden;
  }

  /* .carousel-inner{
  min-height: 70vh !important;
} */

  #sidebarDivider {
    display: none;
  }
}
</style>
