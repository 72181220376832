<template>
  <div id="memebership-details" class="p-2">
    <div v-for="membership in userMemberships" :key="membership.membershipId" class="card p-3 shadow mt-3">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Membership Number:</b></div>
            <div class="col-md-6">{{userInfo.membershipNo}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Status:</b></div>
            <div class="col-md-6">{{membership.membershipStatus}}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Valid From:</b></div>
            <div class="col-md-6">{{membership.validFrom}}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Valid Till:</b></div>
            <div class="col-md-6">{{membership.validTill}}</div>
          </div>
        </div>
      </div>
    
      <div v-if="membership.transactionDetailInfo.paymentId!=null">
        <hr />
      <div class="row mt-2">
        <div class="col">
          <div class="row">
            <div class="col">
              <div><b>Transaction Info</b></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Payment ID:</b></div>
            <div class="col-md-6">{{membership.transactionDetailInfo.paymentId}} <span v-if="membership.transactionDetailInfo.paymentId==null||membership.transactionDetailInfo.paymentId.length==0">NA</span> </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Amount Paid:</b></div>
            <div class="col-md-6"><i class="fas fa-indian-rupee-sign"></i>{{membership.transactionDetailInfo.amount}}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Transaction Date:</b></div>
            <div class="col-md-6">{{membership.transactionDetailInfo.transactionDate}}</div>
          </div>
        </div>
      </div>

    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment"
export default {
  name: "MembershipDetails",
  props: ["UserInfo"],
  data() {
    return {
      userMemberships:[],
      userInfo: {},
    }
  },
  mounted() {
    
  },
  created() {
    this.userInfo = this.UserInfo;

    this.getUserMemberships();
  },
  methods: {
    getUserMemberships(){
      var _this = this;
      var requestBody = {};
      axios
        .post(
          "/api/Membership/UserMemberships",
          requestBody
        )
        .then(function (response) {
          // handle success
          _this.userMemberships = response.data;
          _this.userMemberships.forEach(element => {
            element.validFrom = moment(element.validFrom).format("DD-MMM-yyyy");
            element.validTill = moment(element.validTill).format("DD-MMM-yyyy");
            element.transactionDetailInfo.transactionDate = moment(element.transactionDetailInfo.transactionDate).format("DD-MMM-yyyy");
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
  },
};
</script>

<style>
</style>