<template>
  <div class="email-template-list" id="emailTemplateList">
    <div
      class="modal fade"
      id="emailTemplateListing"
      tabindex="-1"
      role="dialog"
      aria-labelledby="email-template-listingLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <form @submit.prevent="SendEmail">
            <div class="modal-header">
              <h6 class="modal-title" id="emailTemplateListingLabel">
                Select Template
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-left">
              <div class="form-group">
                <label for="templateSelection">Select template to send</label>
                <select
                  id="templateSelection"
                  class="form-control form-control-sm"
                  v-model="sendMailRequestBody.fileInformation"
                >
                  <option
                    v-for="template in templateList"
                    :key="template.fileId"
                    :value="template"
                  >
                    {{ template.fileName }}
                  </option>
                </select>

                <!-- Add New template -->

                <div>
                  <small
                    ><a
                      data-toggle="collapse"
                      href="#addNewTemplate"
                      role="button"
                      aria-expanded="false"
                      aria-controls="addNewTemplate"
                    ><u>Click here </u>
                      <i class="fas fa-file-circle-plus text-secondary"></i> </a
                  >to add new template</small>
                </div>

                <div class="collapse add-new-template" id="addNewTemplate">
                  <div class="card card-body">
                    <form @submit.prevent="addEmailTemplate()">
                      <div class="form-group form-group-sm">
                        <label for="inputDescription">Description (Email Subject)</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="inputDescription"
                          placeholder="File Description"
                          v-model="addFileRequestBody.description"
                          required
                        />
                      </div>

                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input form-control-sm"
                          id="validatedCustomFile"
                          @change="setFile($event)"
                          accept=".html"
                          required
                        />
                        <label
                          class="custom-file-label custom-file-label-sm"
                          for="validatedCustomFile"
                          >Choose file...</label
                        >
                      </div>

                      <div class="row justify-content-center mt-3">
                        <button class="btn btn-spnm btn-sm" type="submit">
                          Add File <i class="fas fa-add ml-2"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-spnm btn-sm">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "EmailTemplateList",
  props:["mailingList"],
  data() {
    return {
      templateList: [],
      loading: false,
      selectedTemplate: null,
      addFileRequestBody: {
        description: null,
        file: null,
      },
      sendMailRequestBody:{
        mailingListCollection:null,
        fileInformation:null
      }
    };
  },
  watch:{
    mailingList:function (newData) {
      this.sendMailRequestBody.mailingListCollection = newData
    }
  },
  created() {
    this.getTemplateList();
    if (this.mailingList) {
      this.sendMailRequestBody.mailingListCollection = this.mailingList 
    }
  },
  mounted() {},
  methods: {
    getTemplateList() {
      var requestBody = {
        FileType: "EmailTemplate",
      };
      var _this = this;
      this.loading = true;
      axios
        .post("/api/FileUploader/FindUploadedFiles", requestBody)
        .then(function (response) {
          //   console.log(response.data.uploadedFileItems);
          _this.templateList = response.data.uploadedFileItems;
          _this.loading = false;
        })
        .catch(function (error) {
          _this.loading = false;
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
    SendEmail() {
      var _this = this;
      this.loading = true;
      this.$store.dispatch("increaseLoadingCounter");
      axios
        .post("/api/MailingListSource/SendMail", this.sendMailRequestBody)
        .then(function (response) {
          _this.$store.dispatch("decreaseLoadingCounter");
          alert("Mail Sent Succesfully!")
        })
        .catch(function (error) {
          _this.loading = false;
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
    setFile(event) {
      this.addFileRequestBody.file = event.target.files[0];
    },
    addEmailTemplate() {
      let formData = new FormData();

      var _this = this;
      this.loading = true;

      formData.append("File", this.addFileRequestBody.file);
      formData.append("ReferenceData", JSON.stringify(this.addFileRequestBody));

      axios
        .post("/api/FileUploader/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function () {
          alert("Template Added Successfully.")
          $(".add-new-template").collapse("toggle");
          _this.getTemplateList();
        })
        .catch(function (error) {
          _this.loading = false;
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style>
</style>