<template>
    <div>
      <div class="card mt-3" style="min-height: 80vh">
        <div id="slideHeading" class="card-header ml-auto mr-auto p-0 mt-3">
          <h5>Contributing Organisations</h5> 
          <span class="borderSection"></span>
        </div>
  
        <div id="slideLogos" class="card-body p-0" style="height: 100%">
          <div class="row" style="min-height: 70vh">
            <div class="col self-align-center" style="height: 100%">

              <div class="row mt-2 mr-0" style="width: 100%; text-align: center">
                <div class="col">
                  <img dir="small"
                    class="mt-1 "
                    src="../../../assets/Contributors/transcon.webp"
                    alt=""
                    height="50"
                    width="90%"
                  />
                </div>
              </div>

              <div class="row mt-2 mr-0" style="width: 100%; text-align: center">
                <div class="col">
                  <img dir="small"
                    class="mt-1 "
                    src="../../../assets/Contributors/isa.webp"
                    alt=""
                    height="100"
                    width="135"
                  />
                </div>
              </div>
  
              <div class="row mt-2 mr-0" style="width: 100%; text-align: center">
                <div class="col">
                  <img dir="small"
                    class="mt-1 "
                    src="../../../assets/Contributors/noble.webp"
                    alt=""
                    height="50"
                    width="85%"
                  />
                </div>
              </div>
              <div class="row mt-2 mr-0" style="width: 100%; text-align: center">
                <div class="col">
                  <img dir="small"
                    class="mt-1"
                    src="../../../assets/Contributors/trikom.webp"
                    alt=""
                    height="45"
                    width="90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "slideFour",
  };
  </script>
  
  <style>
  @media only screen and (max-width: 600px) {
    #slideHeading {
      transform: scale(0.5);
    }
    #slideLogos {
      transform: scale(0.5);
    }
  }
  </style>
  