// import axios from "axios";
// import * as access from "../../MegaScript/getaccess";

const state = {

    UserEvents: null
};

const getters = {

    UserEvents: (state) => state.UserEvents
};

const actions = {
    getUserEvents(userInfo) {
    
        var _this = this;
        var requestBody = {
          MembershipNo: userInfo.membershipNo,
        };
        axios
          .post("/api/EventRegistration/GetUserEvents", requestBody)
          .then(function (response) {
            response.data.forEach(element => {
              element.bookingDate = moment(element.bookingDate).format("DD-MMM-yyyy");
              element.eventDate = moment(element.eventDate).format("DD-MMM-yyyy");
              element.transactionDetailInfo.transactionDate = moment(element.transactionDetailInfo.transactionDate).format("DD-MMM-yyyy");
            });
            
            commit('setUserEvents', response.data)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      },
};

const mutations = {
    setUserEvents: (state, UserEvents) => (state.UserEvents = UserEvents)
};

export default {
    state,
    getters,
    actions,
    mutations,
}