<template>
  <div class="membership-summary text-left">
    <div class="row justify-content-center">
      <u><h6>Summary</h6></u>
    </div>
    <div class="membership-summary-details">
      <label class="text-secondary"> Membership Details :</label>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>First Name</th>
            <td>{{ summaryDetails.firstName }}</td>
            <th>Last Name</th>
            <td>{{ summaryDetails.lastName }}</td>
          </tr>
          <tr>
            <th>Personal Email</th>
            <td colspan="3">{{ summaryDetails.personalEmailId }}</td>
          </tr>
          <tr>
            <th>Official Email</th>
            <td colspan="3">{{ summaryDetails.officialEmailId }}</td>
          </tr>
          <tr>
            <th>Highest Education</th>
            <td>{{ summaryDetails.highestEducation }}</td>
            <th>Industry Vertical</th>
            <td>
              <span v-if="summaryDetails.industryVerticalName">{{
                summaryDetails.industryVerticalName
              }}</span>
              <span v-else>{{ summaryDetails.otherIndustryVertical }}</span>
            </td>
          </tr>

          <tr>
            <th>Company Name</th>
            <td>{{ summaryDetails.companyName }}</td>
            <th>Job Title/Vertical</th>
            <td>{{ summaryDetails.jobTitle }}</td>
          </tr>
          <tr>
            <th>Mob. No.</th>
            <td>{{ summaryDetails.personalMobileNo }}</td>
            <th>Alt. Mob. no.</th>
            <td>{{ summaryDetails.alternativeMobileNo }}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td colspan="3">{{ summaryDetails.address }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="
        summaryDetails.membershipStatus &&
        summaryDetails.membershipStatus != 'Active'
      "
      class="membership-summary-details mt-2"
    >
      <label class="text-secondary"> Payment Details :</label>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td colspan="1">Total Amount To Pay</td>
            <th colspan="1" class="text-center">
              <i v-if="summaryDetails" class="fas fa-indian-rupee-sign"></i
              >{{
                summaryDetails.membershipInfo.transactionDetailInformation
                  .gatewayTransactionInfo.amountToBePaid
              }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-row mb-3 justify-content-center">
      <button
        v-if="
          summaryDetails.membershipInfo.transactionDetailInformation
            .gatewayTransactionInfo.amountToBePaid != 0
        "
        @click="activateLoading()"
        id="rzp-button"
        type="submit"
        class="btn btn-spnm btn-sm"
      >
        <i class="fas fa-credit-card"></i> Pay Now
      </button>
      <button
        v-if="
          summaryDetails.membershipInfo.transactionDetailInformation
            .gatewayTransactionInfo.amountToBePaid == 0
        "
        @click="activateLoading()"
        id="register-button"
        type="button"
        class="btn btn-spnm btn-sm"
      >
        Register
      </button>
    </div>
    <PayButton
      v-if="
        summaryDetails.membershipInfo.transactionDetailInformation
          .gatewayTransactionInfo.amountToBePaid != 0
      "
      :referenceData="gatewayReferenceData"
      @success="handlePaymentSuccess"
      @failure="handlePaymentFailure"
    />
    <div v-if="loading" class="overlay">
      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
  </div>
</template>

<script>
import PayButton from "../PaymentGateway/PayButton.vue";
import axios from "axios";
export default {
  name: "MemershipSummaryDetails",
  props: ["referenceData"],
  components: {
    PayButton,
  },
  data() {
    return {
      loading: false,
      industryVerticals: null,
      gatewayReferenceData: {},
      summaryDetails: null,
    };
  },
  watch: {
    referenceData: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.summaryDetails = val;
        this.gatewayReferenceData.amount =
          this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.amountToBePaid;
        this.gatewayReferenceData.orderId =
          this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentOrderId;
        this.gatewayReferenceData.firstName = this.summaryDetails.firstName;
        this.gatewayReferenceData.lastName = this.summaryDetails.lastName;
        this.gatewayReferenceData.emailId = this.summaryDetails.personalEmailId;
        this.gatewayReferenceData.mobileNo =
          this.summaryDetails.personalMobileNo;
      },
    },
  },
  created() {
    this.industryVerticals = this.$store.getters.IndustryVerticals;

    this.summaryDetails = this.referenceData;

    this.gatewayReferenceData.amount =
      this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.amountToBePaid;
    this.gatewayReferenceData.orderId =
      this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentOrderId;
    this.gatewayReferenceData.firstName = this.summaryDetails.firstName;
    this.gatewayReferenceData.lastName = this.summaryDetails.lastName;
    this.gatewayReferenceData.emailId = this.summaryDetails.personalEmailId;
    this.gatewayReferenceData.mobileNo = this.summaryDetails.personalMobileNo;

    if (this.industryVerticals != null) {
      var verticalId = this.summaryDetails.industryVertical;
      var newArray = this.industryVerticals.filter(function (el) {
        return el.gpId == verticalId;
      });

      if (newArray.length != 0) {
        this.summaryDetails.industryVerticalName = newArray[0].shortDescription;
      }
      else{
        this.summaryDetails.industryVerticalName =this.summaryDetails.otherIndustryVertical
      }
    }
  },
  mounted() {},
  methods: {
    activateLoading() {
      this.loading = true;
      if (
        this.summaryDetails.membershipInfo.transactionDetailInformation
          .gatewayTransactionInfo.amountToBePaid == 0
      ) {
        this.handlePaymentSuccess();
      }
    },
    handlePaymentSuccess(paymentResponse) {
      var _this = this;

      var bodyObject = this.summaryDetails;

      if (paymentResponse) {
        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentOrderId =
          paymentResponse.razorpay_order_id;

        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentId =
          paymentResponse.razorpay_payment_id;

        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.signature =
          paymentResponse.razorpay_signature;
      } else {
        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentOrderId =
          "NA";

        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.paymentId =
          "NA";

        this.summaryDetails.membershipInfo.transactionDetailInformation.gatewayTransactionInfo.signature =
          "NA";
      }

      bodyObject.industryVertical = parseInt(bodyObject.industryVertical);

      // Make a request for a user with a given ID
      axios
        .post("/api/Membership/Register", bodyObject)
        .then(function (response) {
          // handle success
          // userData["paymentDetails"] = paymentData

          if (response.data.membershipInfo) {
            var summaryData = {
              paymentId:
                response.data.membershipInfo.transactionDetailInformation
                  .gatewayTransactionInfo.paymentId,
              acknowledgementNumber:
                "Membership Number: " + response.data.membershipNo,
              message:
                "Registered Successfully. For more info please check your mail.",
            };
          } else {
            var summaryData = {
              paymentId: "NA",
              acknowledgementNumber:
                "Membership Number: " + response.data.membershipNo,
              message:
                "Registered Successfully. For more info please check your mail.",
            };
          }

          _this.$emit("proceed", "PaymentSuccess", summaryData);
          _this.loading = false;

          console.log(_this.$store.getters.IsAdmin)

          if (_this.$store.getters.IsAdmin) {
            _this.$emit("reset");
          }

          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; //For Chrome
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
          _this.loading = false;
        });
    },
    handlePaymentFailure(paymentResponse) {
      _this.loading = false;
    },
  },
};
</script>

<style>
</style>