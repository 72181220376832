<template>
  <div class="text-left">
    <div v-if="userInfo != null">
      <div class="row">
        <div class="col-md-4 text-center">
          <img dir="small" src="../assets/userImage.webp" height="100" width="120" alt="" />
        </div>
        <div class="col-md-6">
          <div class="row mt-1">
            <div class="col-4"><b>Name :</b></div>
            <div class="col-8">
              {{ userInfo.firstName }} {{ userInfo.lastName }}
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-4"><b>Job Title :</b></div>
            <div class="col-8">{{ userInfo.jobTitle }}</div>
          </div>
          <div class="row mt-1">
            <div class="col-4"><b>Membership No. :</b></div>
            <div class="col-8">{{ userInfo.membershipNo }}</div>
          </div>

          <div class="row mt-1">
            <div class="col-4"><b>Membership Status :</b></div>
            <div class="col-8">
              <span
                v-if="userInfo.membershipStatus == 'Active'"
                class="badge badge-success"
                >{{ userInfo.membershipStatus }}</span
              >
              <span
                v-if="userInfo.membershipStatus == 'InActive'"
                class="badge badge-danger"
                >{{ userInfo.membershipStatus }}</span
              >
              <span v-if="userInfo.membershipStatus == 'InActive'"
                ><button
                  type="button"
                  class="btn btn-warning btn-sm ml-2"
                  style="
                    padding: 0.15rem 0.3rem;
                    font-size: 0.675rem;
                    border-radius: 0.5rem;
                  "
                  data-toggle="modal"
                  data-target="#membershipFormModal"
                  v-if="$store.getters.LoggedUserInfo.userId == userInfo.userId"
                >
                  Upgrade <span class="fas fa-upload"></span></button
              ></span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4"><b>Membership Valid Till : </b></div>
            <div class="col-8">{{ userInfo.membershipValidTill }}</div>
          </div>
        </div>

        <div class="col-md-2">
          <button
            type="button"
            data-toggle="modal"
            data-target="#membershipFormModal"
            class="btn btn-light btn-sm"
          >
            <i class="fas fa-user-edit"></i>
            Edit Profile
          </button>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                >Personal Info</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#profile"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >Membership Details</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                >Event Bookings</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="changePassword-tab"
                data-toggle="tab"
                href="#changePassword"
                role="tab"
                aria-controls="changePassword"
                aria-selected="false"
                v-if="$store.getters.LoggedUserInfo.userId == userInfo.userId"
                >Change Password</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div v-if="userInfo != null">
                <UserDetails :UserInfo="userInfo" />
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div v-if="userInfo != null">
                <MembershipDetails :UserInfo="userInfo" />
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div v-if="userInfo != null">
                <EventDetails :UserInfo="userInfo" />
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="changePassword"
              role="tabpanel"
              aria-labelledby="changePassword-tab"
              v-if="$store.getters.LoggedUserInfo.userId == userInfo.userId"
            >
              <div v-if="userInfo != null">
                <ChangePassword :UserInfo="userInfo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MembershipModal />
  </div>
</template>

<script>
import axios from "axios";
import UserDetails from "../components/UserProfile/UserDetails.vue";
import MembershipDetails from "../components/UserProfile/MembershipDetails.vue";
import EventDetails from "../components/UserProfile/EventDetails.vue";
import ChangePassword from "../components/UserProfile/ChangePassword.vue";
import MembershipModal from "../components/Membership/MembershipRegistrationModal.vue";
import moment from "moment";
export default {
  name: "Profile",
  props: ["userDetails"],
  components: {
    UserDetails,
    MembershipDetails,
    EventDetails,
    ChangePassword,
    MembershipModal,
  },
  data() {
    return {
      industryVerticals: null,
      userInfo: null,
      membershipDetails: {},
    };
  },
  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData;
      if (this.userInfo) {
        this.userInfo.membershipValidTill = moment(
          this.userInfo.membershipValidTill
        ).format("DD-MMM-yyyy");
        this.setIndustryVertical(this.userInfo.industryVertical);
      }
    },
    watchIndustryVerticals: function () {
      if (this.userInfo) {
        this.setIndustryVertical(this.userInfo.industryVertical);
      }
    },
    userDetails: function (newData) {
      this.userInfo = newData;
      if (this.userInfo) {
        this.setIndustryVertical(this.userInfo.industryVertical);
      }
    },
  },

  computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
    watchIndustryVerticals() {
      return this.$store.getters.IndustryVerticals;
    },
  },
  created() {
    this.userInfo = this.$store.getters.LoggedUserInfo;
    this.industryVerticals = this.$store.getters.IndustryVerticals;

    if (this.userDetails) {
      this.userInfo = this.userDetails;
    }

    if (this.userInfo) {
      this.setIndustryVertical(this.userInfo.industryVertical);
    }

    if (this.userInfo) {
      this.userInfo.membershipValidTill = moment(
        this.userInfo.membershipValidTill
      ).format("DD-MMM-yyyy");
    }
  },
  methods: {
    setIndustryVertical() {
      if (this.industryVerticals != null) {
        var verticalId = this.userInfo.industryVertical;
        var newArray = this.industryVerticals.filter(function (el) {
          return el.gpId == verticalId;
        });

        if (newArray.length != 0) {
          this.userInfo.industryVerticalName = newArray[0].shortDescription;
        } else {
          this.userInfo.industryVerticalName =
            this.userInfo.otherIndustryVertical;
        }
      }
    },
  },
};
</script>

<style>
</style>