<template>
  <!-- Modal -->
  <div
    class="modal fade"

    :id="modalId"
    tabindex="-1"
    :aria-labelledby="'registrations_' + modalId"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog
        modal-dialog-centered
        modal-dialog-scrollable
        modal-xl
      "
      style="height:fit-content !important"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">
            Event Registration List
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <CoreDatatable
            v-if="tableData.tableHeaders != null"
            :dataBase="tableData.dataBase"
            :tableHeaders="tableData.tableHeaders"
            :title="'User List'"
            :dynamicTableId="dataTableID"
            @onTableReady="enableDatatableFeatures"
          />
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CoreDatatable from "../Core/Datatable.vue";
import axios from "axios";
import $ from "jquery";

var table;
var databaseLength = 0;

export default {
  name: "EventRegistrationList",
  props: ["eventInfo", "modalId"],
  components: {
    CoreDatatable,
  },
  data() {
    return {
      mailToSend: [],
      dataTableID: "EventListTable",
      tableData: {
        dataBase: [],
        tableHeaders: null,
        title: "User Data",
      },
    };
  },
  created() {
    this.getRegistrationList();
    this.dataTableID = "EventListTable"+this.eventInfo.eventId
  },
  mounted() {},
  methods: {
    getRegistrationList() {
      var requestBody = {
        eventId: this.eventInfo.eventId,
      };
      var _this = this;
      axios
        .post("/api/EventRegistration/GetEventRegistrationsList", requestBody)
        .then(function (response) {
          _this.generateTable(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console, log(error);
          }
        });
    },

    generateTable(dataBase) {

      var tableHeaders = [
        {
          title:
            '<input type="checkbox" class=" mt-0 ml-0 er-select-all" id="erSelectAll"> ',
          data: "userId",
          className: "dt-left",
          orderable: false,
          render: function (data, type) {
            return '<input type="checkbox" class="form-check-input ml-1 er-select-checkBox" id="erSelectCheckbox">';
          },
        },
        {
          title: "Membership No.",
          data: "membershipNo",
          className: "dt-left",
        },

        {
          title: "Acknowledgement No.",
          data: "acknowledgementNumber",
          className: "dt-left",
        },

        {
          title: "First Name",
          className: "dt-left",
          data: "firstName",
        },
        {
          title: "Last Name",
          className: "dt-left",
          data: "lastName",
        },
        {
          title: "Company Name",
          className: "dt-left",
          data: "companyName",
        },

        {
          title: "Mail ID",
          className: "dt-left",
          data: "emailId",
        },
        {
          title: "Contact Number",
          className: "dt-left",
          data: "mobileNumber",
        },

        {
          title: "Attendence",
          data: "attended",
          className: "text-center dt-center",
          orderable: false,
          render: function (data, type) {
            if (type === "display") {
              return '<input type="checkbox" class="form-check-input ml-1 mark-attendence" id="markAttendence">';
            }
            return data;
          },
        },
      ];
      this.tableData.dataBase = dataBase; 
      this.tableData.tableHeaders = tableHeaders;
      
    },

    enableDatatableFeatures(database) {
      table = $("#dashboardTable" + this.dataTableID).DataTable();

      var _this = this;
      $("#dashboardTable" + this.dataTableID + " tbody").on(
        "click",
        "input.mark-attendence",

        function () {
          var rowdata = table.row($(this).parents("tr")).data();
        
          _this.markAttendence(rowdata);
        }
      );


      $("#dashboardTable" + this.dataTableID + " tbody").on(
        "click",
        "input.er-select-checkBox",

        function () {
          var rowdata = table.row($(this).parents("tr")).data();
          let obj = _this.mailToSend.find((o) => o.userId === rowdata.userId);

          if (obj) {
            _this.mailToSend.splice(
              _this.mailToSend.findIndex((a) => a.userId === rowdata.userId),
              1
            );
          } else {
            _this.mailToSend.push(rowdata);
          }

          if (_this.mailToSend.length == databaseLength) {
            _this.mailToSend = _this.tableData.dataBase;
            $(".er-select-all").prop("checked", true);
          } else {
            $(".er-select-all").prop("checked", false);
          }
        }
      );

      $("#dashboardTable" + this.dataTableID + " thead").on(
        "click",
        "input.er-select-all",

        function () {
          if (_this.mailToSend.length != parseInt(databaseLength)) {
            _this.mailToSend = _this.tableData.dataBase;
            $(".er-select-checkBox").prop("checked", true);
          } else {
            _this.mailToSend = [];
            $(".er-select-checkBox").prop("checked", false);
          }
        }
      );
    },

    markAttendence(registrationData){
      if(registrationData.attended=="N"){
        registrationData.attended = "Y";
      }
      else{
        registrationData.attended = "N";
      }

      var requestBody = registrationData;
      var _this = this;
      axios
        .post("/api/EventRegistration/MarkAttendence", requestBody)
        .then(function (response) {
          alert("Attendence Updated.")
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console, log(error);
          }
        });
    }
  },
};
</script>

<style>
</style>