<template>
  <div id="event-details" class="p-2">
    <div
      v-if="userEventRegistrations.length == 0"
      class="row mt-4 text-secondary justify-content-center"
    >
      <div class="col">
        <div class="row justify-content-center">
          <h1><i class="fa fa-exclamation-circle" aria-hidden="true"></i></h1>
        </div>
        <div class="row justify-content-center">
          <h4>You haven't registered for any event yet .</h4>
        </div>
      </div>
    </div>
    <div
      v-for="registration in userEventRegistrations"
      :key="registration.eventRegistrationId"
      class="card p-3 shadow mt-3 text-left"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Acknowledgement No.:</b></div>
            <div class="col-md-6">
              #{{ registration.acknowledgementNumber }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Booking Date:</b></div>
            <div class="col-md-6">
              {{ registration.bookingDate }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="row">
            <div class="col-md-3"><b>Event Name:</b></div>
            <div class="col-md-9">{{ registration.eventTitle }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Event Date:</b></div>
            <div class="col-md-6">{{ registration.eventDate }}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Event Time:</b></div>
            <div class="col-md-6">{{ registration.eventTime }}</div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Applied Amount:</b></div>
            <div class="col-md-6">
              <i class="fas fa-indian-rupee-sign"></i>
              {{ registration.transactionDetailInfo.rate }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- <div class="row">
            <div class="col-md-6"><b>You Saved:</b></div>
            <div class="col-md-6">
              <i class="fas fa-indian-rupee-sign"></i>
              {{ (registration.transactionDetailInfo.rate/registration.eventInfo.nonMemberEntryFee)*100 }}%
            </div>
          </div> -->
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <button
                v-if="registration.eventCollectionItems.length != 0"
                type="button"
                class="btn btn-light btn-sm"
                data-toggle="collapse"
                :href="'#coattendes_' + registration.eventRegistrationId"
                role="button"
                aria-expanded="false"
                style="
                  padding: 0.15rem 0.3rem;
                  font-size: 0.675rem;
                  border-radius: 0.5rem;
                "
                :aria-controls="
                  'coattendes_' + registration.eventRegistrationId
                "
              >
                <i class="fa fa-users text-secondary" aria-hidden="true"></i>
                Co-Attendes ({{ registration.eventCollectionItems.length }})
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-right">
          <div class="row">
            <div class="col-md-12">
              <button
                style="
                  padding: 0.15rem 0.3rem;
                  font-size: 0.675rem;
                  border-radius: 0.5rem;
                "
                class="btn btn-light btn-sm"
                data-toggle="modal"
                @click="handleEventInfoModal(registration.eventInfo)"
                :data-target="'#userEventInfo'"
              >
                <i
                  class="fa fa-exclamation-circle text-secondary"
                  aria-hidden="true"
                ></i>
                Event Info
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div
            class="collapse"
            :id="'coattendes_' + registration.eventRegistrationId"
          >
            <div class="card card-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Membership No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Contact Number</th>
                    <th scope="col">Company</th>
                    <th scope="col">Applied Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="attendee in registration.eventCollectionItems"
                    :key="attendee.eventRegistrationId"
                  >
                    <td>
                      {{ attendee.membershipNo }}
                      <span v-if="attendee.membershipNo.length == 0">NA</span>
                    </td>
                    <td>{{ attendee.firstName }} {{ attendee.lastName }}</td>
                    <td>{{ attendee.emailId }}</td>
                    <td>{{ attendee.mobileNumber }}</td>
                    <td>{{ attendee.companyName }}</td>
                    <td> <i class="fas fa-indian-rupee-sign"></i> {{attendee.transactionDetailInfo.amount/attendee.transactionDetailInfo.quantity}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-2">
        <div class="col">
          <div class="row">
            <div class="col">
              <div><b>Payment Info</b></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Payment ID:</b></div>
            <div class="col-md-6">
              {{ registration.transactionDetailInfo.paymentId }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Total Amount Paid:</b></div>
            <div class="col-md-6">
              <i class="fas fa-indian-rupee-sign"></i>
              {{
                registration.transactionDetailInfo.gatewayTransactionInfo
                  .amountToBePaid
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6"><b>Transaction Date:</b></div>
            <div class="col-md-6">
              {{ registration.transactionDetailInfo.transactionDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <KnowMore
      v-if="eventInformation != null"
      :eventInfo="eventInformation"
      :modalId="'userEventInfo'"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import KnowMore from "../Events/KnowMore.vue";
export default {
  name: "EventDetails",
  props: ["UserInfo"],
  components: {
    KnowMore,
  },
  data() {
    return {
      eventInformation: null,
      userEventRegistrations: [],
      userInfo: {},
    };
  },
  mounted() {},
  created() {
    this.userInfo = this.UserInfo;
    this.getUserEvents();
  },
  methods: {
    handleEventInfoModal(eventInfo) {
      this.eventInformation = eventInfo;
    },
    getUserEvents() {
      var _this = this;
      var requestBody = {
        MembershipNo: this.userInfo.membershipNo,
      };
      axios
        .post("/api/EventRegistration/GetUserEvents", requestBody)
        .then(function (response) {
          // handle success
          _this.userEventRegistrations = [];

          response.data.forEach((element) => {
            element.bookingDate = moment(element.bookingDate).format(
              "DD-MMM-yyyy"
            );
            element.eventDate = moment(element.eventDate).format("DD-MMM-yyyy");
            element.transactionDetailInfo.transactionDate = moment(
              element.transactionDetailInfo.transactionDate
            ).format("DD-MMM-yyyy");
            var otherAttendes = [];
            element.eventCollectionItems.forEach((subElement) => {
              if (subElement.membershipNo != _this.userInfo.membershipNo) {
                otherAttendes.push(subElement);
              }
            });
            element.eventCollectionItems = otherAttendes;
            _this.userEventRegistrations.push(element);
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    },
  },
};
</script>

<style>
</style>