import axios from "axios";


export function Logout() {

    axios
        .post("/api/Auth/Logout", {})
        .then(function (response) {
            location.reload();
        })
        .catch(function (error) {
            console.log(error)
        });
}