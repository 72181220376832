<template>
  <div>
    <div class="card ">
      <div
        id="slideHeading"
        class="card-header ml-auto mr-auto p-0 mt-3"
      
      >
      <h5>Media Partners</h5>
        
        <span class="borderSection"></span>
      </div>

      <div id="slideLogos" class="card-body p-0 mt-5">
        <div class="row mt-5" style="height: 100%">
          <div class="col ">
            <div class="row" style="width: 100%; text-align: center">
              <div class="col">
                <img dir="small"
                  src="../../../assets/partners/exim.webp"
                  alt=""
                  height="120"
                  width="150"
                />
              </div>
            </div>
            <div class="row mt-5" style="width: 100%; text-align: center">
              <div class="col">
                <img dir="small"
                   src="../../../assets/partners/MARITIME.webp"
                  alt=""
                  height="90"
                  width="160"
                />
              </div>
            </div>
            
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slideOne",
};
</script>

<style>
@media only screen and (max-width: 600px) {
  #slideHeading {
    transform: scale(0.5);
  }
  #slideLogos img{
    transform: scale(0.5);
  }
}

.card {
  align-content: center;
  height: 100%;

  border: 0px solid #3b5aa7;
  border-bottom: 0px;
}
.card-header {
  background-color: rgb(255, 255, 255);
}
.borderSection {
  display: block;
  height: 2px;
  border: 0;
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
  background-color: none !important;

  background: linear-gradient(90deg, rgba(59, 90, 167, 1) 21%, rgba(255, 0, 0, 1) 100%);
}

.card-header {
  padding: 0.55rem 1.25rem;
  margin-bottom: 0;

  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
</style>
