<template>
  <div id="event-list" class="">
    <div class="row justify-content-center">
      <h5 class="ml-auto mr-auto">Event List</h5>
      <button
        type="button"
        class="btn btn-light btn-sm"
        data-toggle="modal"
        data-target="#addEvent"
      >
        <i class="fas fa-add"></i>
      </button>
    </div>

    <hr />
    <div v-if="!loading" class="list p-1 text-left">
      <div v-for="event in allEvents" :key="event.eventId" class="p-2">
        <div class="row">
          <div class="col-md">
            <div class="row">
              <div class="col-md-3 text-secondary">
                <p><b>Event Name :</b></p>
              </div>
              <div class="col-md">
                <p>
                  <i
                    ><b>{{ event.title }}</b>
                    <small> ("{{ event.tagLine }}")</small></i
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="col-1">
            <button
              type="button"
              class="btn btn-light btn-sm"
              data-toggle="modal"
              :data-target="'#addEvent' + event.eventId"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              @click="deleteEvent(event.eventId)"
            >
              <i class="fas fa-trash-can text-danger"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md text-secondary">
                <p><b>Event Date : </b></p>
              </div>
              <div class="col-md">
                <p>{{ event.eventDate }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md text-secondary">
                <p><b>Event Time :</b></p>
              </div>
              <div class="col-md">
                <p>{{ event.eventTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md text-secondary">
                <p><b>Registration Starts On : </b></p>
              </div>
              <div class="col-md">
                <p>{{ event.regStartDate }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md text-secondary">
                <p><b>Registration Ends On :</b></p>
              </div>
              <div class="col-md">
                <p>{{ event.regEndDate }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md text-secondary">
                <p><b>Event Status : </b></p>
              </div>
              <div class="col-md">
                <span class="badge badge-success">Active</span>
                <span
                  v-if="event.eventStatus == 'Active'"
                  class="badge badge-success"
                  >{{ event.eventStatus }}</span
                >
                <span
                  v-if="event.eventStatus == 'InActive'"
                  class="badge badge-success"
                  >{{ event.eventStatus }}</span
                >
              </div>
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-md-4">
            <button
              style="
                padding: 0.15rem 0.3rem;
                font-size: 0.675rem;
                border-radius: 0.5rem;
              "
              class="btn btn-info btn-sm"
              data-toggle="modal"
              :data-target="'#adminEventInfo' + event.eventId"
              @click="handleEventInfoModal(event)"
            >
              <i
                class="fa fa-exclamation-circle text-light"
                aria-hidden="true"
              ></i>
              Event Info
            </button>
          </div>
          <div class="col-md-4 text-center">
            <button
              type="button"
              class="btn btn-warning btn-sm"
              role="button"
              aria-expanded="false"
              data-toggle="modal"
              :data-target="'#eventInvite' + event.eventId"
              style="
                padding: 0.15rem 0.3rem;
                font-size: 0.675rem;
                border-radius: 0.5rem;
              "
              @click="handleEventregistrationInfoModal"
            >
              <i class="fa fa-envelope" aria-hidden="true"></i>
              Event Invitations
            </button>
          </div>
          <div class="col-md-4 text-right">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              role="button"
              aria-expanded="false"
              data-toggle="modal"
              :data-target="'#eventRegistrations' + event.eventId"
              style="
                padding: 0.15rem 0.3rem;
                font-size: 0.675rem;
                border-radius: 0.5rem;
              "
              @click="handleEventregistrationInfoModal"
            >
              <i class="fa fa-users text-light" aria-hidden="true"></i>
              Event Registrations
            </button>
          </div>
        </div>
        <AddEvent
          :eventInfo="event"
          :modalId="'addEvent' + event.eventId"
          @recordSave="refreshList"
        />
        <EventRegistrationList
          :eventInfo="event"
          :modalId="'eventRegistrations' + event.eventId"
        />
        <KnowMore
          :eventInfo="event"
          :modalId="'adminEventInfo' + event.eventId"
        />
        <EventInviteList
          :eventInfo="event"
          :modalId="'eventInvite' + event.eventId"
        />
        <hr />
      </div>
    </div>

    <div v-if="loading" style="height:50vh">
    <div class="overlay p-5">
      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
    </div>
    <AddEvent
      :eventInfo="eventInformation"
      :modalId="'addEvent'"
      @recordSave="refreshList"
    />
  </div>
</template>
  
  <script>
import { generate } from "@vue/compiler-core";
import axios from "axios";
import CoreDatatable from "../Core/Datatable.vue";
import moment from "moment";
import KnowMore from "../Events/KnowMore.vue";
import EventRegistrationList from "../Events/EventRegistrationList.vue";
import EventInviteList from "../Events/EventInviteList.vue";
import AddEvent from "../Events/AddEvent.vue";
export default {
  name: "EventList",
  components: {
    CoreDatatable,
    KnowMore,
    EventRegistrationList,
    EventInviteList,
    AddEvent,
  },
  data() {
    return {
      eventInformation: null,
      userInfo: null,
      allEvents: [],
      loading:true,
      tableData: {
        dataBase: [],
        tableHeaders: null,
        title: "User Data",
      },
    };
  },
  created() {},
  mounted() {
    this.getDataBase();
  },
  methods: {
    refreshList() {
      this.getDataBase();
    },
    deleteEvent(eventId) {
      var _this = this;
      const reqBody = {
        EventPKIds: eventId.toString(),
      };
      axios
        .post(window.location.origin + "/api/Event/DeleteEvents", reqBody, {
          withCredentials: true,
        })
        .then(function (resp) {
          _this.getDataBase();
          alert("Event Deleted.");
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },
    handleEventInfoModal(eventInfo) {
      this.eventInformation = eventInfo;
    },
    handleEventregistrationInfoModal(eventInfo) {
      this.eventInformation = eventInfo;
    },
    getDataBase() {
      var _this = this;
      var requestBody = {};
      this.loading = true;
      axios
        .post("/api/Event/GetEventsList", requestBody)
        .then(function (response) {
          _this.loading = false;
          _this.allEvents = response.data.eventCollectionItems;
          _this.allEvents.forEach((element) => {
            element.eventDate = moment(element.eventDate).format("DD-MMM-yyyy");
            element.lastModifiedDate = moment(element.lastModifiedDate).format(
              "DD-MMM-yyyy"
            );
            element.regEndDate = moment(element.regEndDate).format(
              "DD-MMM-yyyy"
            );
            element.regStartDate = moment(element.regStartDate).format(
              "DD-MMM-yyyy"
            );
          });
        })
        .catch(function (error) {
          _this.loading = false;
          // handle error
          console.log(error);
        });
    },
    generateTable(dataBase) {
      var tableHeaders = [
        {
          title: "Membership No.",
          data: "membershipNo",
          className: "dt-left",
          render: function (data, type) {
            return (
              '<i id="delete" class="fas fa-info-circle mr-1"> </i>' + data
            );
          },
        },
        {
          title: "Membership Status",
          className: "dt-center",
          data: "membershipStatus",
          render: function (data, type) {
            if (data == "Active") {
              return '<span class="badge badge-success text-center">Active</span>';
            } else {
              return '<span class="badge badge-danger text-center">InActive</span>';
            }
          },
        },
        {
          title: "First Name",
          className: "dt-left",
          data: "firstName",
        },
        {
          title: "Last Name",
          className: "dt-left",
          data: "lastName",
        },
        {
          title: "Company Name",
          className: "dt-left",
          data: "companyName",
        },
        {
          title: "Job Title",
          className: "dt-left",
          data: "jobTitle",
        },
        {
          title: "Personal Mail ID",
          className: "dt-left",
          data: "personalEmailId",
        },
        {
          title: "Contact Number",
          className: "dt-left",
          data: "personalMobileNo",
        },

        {
          title: "Attendence",
          data: "userId",
          className: "text-center dt-center",
          orderable: false,
          render: function (data, type) {
            if (type === "display") {
              return '<input type="checkbox" class="form-check-input ml-1 mark-attendence" id="markAttendence">';
            }
            return data;
          },
        },
      ];

      this.tableData.tableHeaders = tableHeaders;
      this.tableData.dataBase = dataBase;
    },
  },
};
</script>
  
  <style>
</style>