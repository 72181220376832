// import axios from "axios";
// import * as access from "../../MegaScript/getaccess";

const state = {

    IsAdmin: false
};

const getters = {

    IsAdmin: (state) => state.IsAdmin
};

const actions = {


};

const mutations = {
    setIsAdmin: (state, IsAdmin) => (state.IsAdmin = IsAdmin)
};

export default {
    state,
    getters,
    actions,
    mutations,
}