<template>
  <div class="h-100" id="CoreDataTable">
    <div class="h-100" :id="'myTable' + dataTableID">
      <table></table>
    </div>
    <div v-if="unchangedData.length != 0">
      <!-- <Filter
        :dataSet="unchangedData"
        @onClick="handleFilterDataset"
        :filterId="filterid"
        :columnInfo="columnInfo"
        :tableheaders="columnNames"
      /> -->
    </div>
    <div v-if="reportId != null">
      <!-- <DrillDown
        :urlPath="drillDownReportPath"
        :modalID="'reportDashboardDrilldownmodal' + reportId"
        id="datatableDrilldown"
      /> -->
    </div>
    <div v-if="noData" class="row noData" style="text-align: center">
      <div class="col">
        <i class="fas fa-exclamation-circle fa-lg mb-3 mt-5"></i><br />
        <h5>No Data Available !</h5>
      </div>
    </div>
  </div>
</template>

<script>
import * as exporter from "../../GeneralScripts/DatatableButtonExportOpt";
import $ from "jquery";
// import Filter from "./ReportDashboard/Filter.vue";
// import DrillDown from "./ReportDashboard/DrillDown.vue";
// import * as notification from "../MegaScipts/successNotification";
export default {
  name: "CoreDatatable",
  props: ["dataBase", "cDataTypes", "tableHeaders", "title", "dynamicTableId"],
  components: {
    // Filter,
    // DrillDown,
  },
  data() {
    return {
      reportId: null,
      dataTableID: "table",
      drillDownshow: false,
      filterid: "filterModal",
      unchangedData: [],
      tableData: null,
      columnNames: null,
      reportTitle: null,
      columnInfo: null,
      drillDownReportPath: null,
      noData: false,
    };
  },

  watch: {
    dataBase: function (newVal, oldVal) {
      this.tableData = newVal;
      this.generateDatatable(
        newVal,
        this.columnNames,
        this.cDataTypes,
        this.reportTitle
      );
    },
  },

  created() {
    this.unchangedData = this.dataBase;
    this.tableData = this.dataBase;
    this.columnNames = this.tableHeaders;
    this.reportTitle = this.title;
    this.dataTableID = this.dynamicTableId;
    this.columnInfo = this.cDataTypes;
    this.filterid = "filterModal" + this.dynamicTableId;
  },

  mounted() {
    if (this.$route) {
      this.reportId = this.$route.params.id;
    } else if (this.dynamicTableId) {
      this.reportId = this.dynamicTableId;
    } else {
      this.reportId = 0;
    }

    if (this.tableData != null) {
      this.generateDatatable(
        this.tableData,
        this.columnNames,
        this.reportTitle
      );
    }
  },

  methods: {
    handleFilterDataset(dataset) {
      this.generateDatatable(
        dataset,
        this.columnNames,
        this.reportTitle
      );
    },
    drillDown(path) {
      this.drillDownReportPath = null;
      this.drillDownReportPath = "GetReportData/" + path;
      $("#reportDashboardDrilldownmodal" + this.reportId).modal("show");
    },
    generateDatatable(dataBase, tableHeaders, title) {
      var _self = this;

      if (tableHeaders.length != 0) {
        _self.noData = false;

        $("#myTable" + _self.dataTableID).html('');

        $("#myTable" + _self.dataTableID).html(
          `<table id="dashboardTable${_self.dataTableID}" class="table" style="width: 100%"></table>`
        );

        $("#dashboardTable" + _self.dataTableID).DataTable({
          dom:
            "<'row p-0'<'col-md-4 text-left p-0'B><'col-md-4 text-center p-0 mt-1'f><'col-md-4 text-right p-0 mt-1'l>>" +
            "<'row p-0 modalTable'<'col-sm-12 m-0 p-0 mainDataTable costume-scroller'tr>>" +
            "<'row p-0 mt-2'<'col-sm-5 p-0'i><'col-sm-7 p-0'p>>",
          data: dataBase,
          columns: tableHeaders,
          order: [],
          autoWidth: false,
          buttons: exporter.exportoptions(title, _self.filterid),
          language: {
            searchPlaceholder: `Search`,
            search: "",
          },

          rowCallback: function (row, data) {
            $("input.user-active", row).prop("checked", data.active == "Y");

            $("input.mark-attendence", row).prop("checked", data.attended == "Y");
            
            if (data.active == "N")
              $("a.userConfigButton", row).addClass("disabledUserConfig");
          },
          // language: {
          //   info: "_TOTAL_ Entries",
          // },
        });

        _self.$emit("onTableReady",dataBase);
      } else {
        $("#myTable" + _self.dataTableID).html(``);
        _self.noData = true;
      }
      $(".btn-secondary").addClass("btn-sm");

      $(document).ready(function () {
        $("#dashboardTable" + _self.dataTableID).DataTable();
      });

      

      $("#dashboardTable" + _self.dataTableID + " tbody td .tdURL").click(
        function () {
          var path = $(this)[0].id.slice(4, 100);

          _self.drillDownshow = true;
          _self.drillDown(path);
        }
      );
    
    },
  },
};
</script>

<style>
.dataTables_wrapper .btn-secondary {
  background-color: #3b5aa7 !important;
  color: #ffffff !important;
  border-color: #3b5aa7;
}

.dataTables_wrapper .buttons-print,
.dataTables_wrapper .buttons-excel {
  border-left: 1px solid #fff;
}

.dataTables_wrapper .buttons-excel {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dataTables_wrapper .row {
  margin: 0px;
  padding-right: 15px;
}
.dataTables_wrapper select {
  width: max-content !important;
}

table.dataTable thead tr {
  background-color: #3b5aa7;
  color: #ffffff;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #3b5aa7 !important;
  border-color: #3b5aa7 !important;
}

/* .dataTables_scrollHeadInner {
  width: 100% !important;
} */
/* .dataTables_filter label {
  display: grid !important;
} */

.dt-buttons .btn {
  margin-top: 0.25rem !important;
}

.dataTables_filter label {
  white-space: inherit !important;
}

.noData {
  text-align: center;
  align-content: center;
  color: rgba(0, 0, 0, 0.5);
}

/* .dataTables_scrollHeadInner .table {
  width: 100% !important;
} */

.dataTables_wrapper .mainDataTable {
  overflow-x: auto;
}

.dataTables_filter {
  text-align: center !important;
}

.dataTables_filter input {
  border-radius: 2rem !important;
}

.dataTables_filter label,
.dataTables_filter input {
  width: 100% !important;
}

table.dataTable td,
table.dataTable th {
  white-space: nowrap !important;
}

.table td {
  padding: 0.3rem !important;
}

td {
  transition: 1s;
}

/* table.dataTable td:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
/* .modal-header {
  align-content: center;
} */
.tdcursor:hover {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.action {
  cursor: pointer;
}

.fa-info-circle{
  color: darkgray;
}
</style>
