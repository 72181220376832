<template></template>

<script>
export default {
  name: "PayButton",
  props: ["referenceData"],

  data() {
    return {
      refData:null
    };
  },
  watch: {
    referenceData: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.refData = val;
      },
    },
  },
  created() {
    this.refData = this.referenceData;
   
  },
  mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //   "src",
    //   "https://checkout.razorpay.com/v1/checkout.js"
    // );
    // document.head.appendChild(recaptchaScript);

    this.payNow();
  },

  methods: {
    payNow() {
      var _this = this;
      var options = {
        key: "rzp_live_juUlaWHcKdQyCa", // Enter the Key ID generated from the Dashboard
        amount: parseInt(this.refData.amount)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "SPN-Mumbai",
        description: "Membership Registration Payment",
        image: "https://spnm.in/img/image.d36e8ff4.webp",
        order_id: this.refData.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          _this.paymentSuccess(response);
        },
        prefill: {
          name: this.refData.firstName+" "+this.refData.lastName,
          email: this.refData.emailId,
          contact: this.refData.mobileNo,
        },
        notes: {
          address: "",
        },
        theme: {
          color: '#3b5aa7',
        },
      };

      var rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        _this.paymentFailure(response.error);
      });
      document.getElementById("rzp-button").onclick = function (e) {
    
        rzp1.open();
        e.preventDefault();
      };
    },
    paymentSuccess(response) {
      console.log(response)
      this.$emit("success", response);
    },
    paymentFailure(response) {
      this.$emit("failure", response);
    },
  },
};
</script>

<style>
</style>