<template>
  <div
    class="modal fade text-left"
    :id="modalId"
    tabindex="-1"
    :aria-labelledby="'registrations_' + modalId"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog
        modal-dialog-centered
        modal-dialog-scrollable
        modal-xl
      "
      style="height:fit-content !important"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLabel">Add/Update Event</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveEvent">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Title">Title</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="Title"
                  v-model="addeventObject.title"
                  maxlength="254"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="TagLine">Tag Line</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="TagLine"
                  maxlength="254"
                  v-model="addeventObject.tagLine"
                  required
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="ShortDescription<">Short Description</label>
                <textarea
                  class="form-control form-control-sm"
                  id="ShortDescription"
                  rows="3"
                  maxlength="254"
                  v-model="addeventObject.shortDescription"
                  required
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label for="Description1">Description 1</label>
                <textarea
                  class="form-control form-control-sm"
                  id="Description1"
                  rows="3"
                  maxlength="1000"
                  v-model="addeventObject.description1"
                  required
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Description2">Description 2</label>
                <textarea
                  class="form-control form-control-sm"
                  id="Description2"
                  rows="3"
                  maxlength="1000"
                  v-model="addeventObject.description2"
                  required
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label for="Description3">Description 3</label>
                <textarea
                  class="form-control form-control-sm"
                  id="Description3"
                  rows="3"
                  maxlength="1000"
                  v-model="addeventObject.description3"
                  required
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="EventDate">Event Date</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="EventDate"
                  v-model="addeventObject.eventDate"
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="EventTime">Event Time</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="EventTime"
                  maxlength="255"
                  v-model="addeventObject.eventTime"
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="EventMode">Event Mode</label>
                <select
                  class="custom-select custom-select-sm my-1 mr-sm-2"
                  id="EventMode"
                  v-model="addeventObject.eventMode"
                  required
                >
                  <option v-for="mode in eventModes" :key="mode.gpId" :value="mode.gpId">{{mode.shortDescription}}</option>
                 
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="RegistrationStartDate"
                  >Registration Start Date
                </label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="RegistrationStartDate"
                  v-model="addeventObject.regStartDate"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="RegistrationStartDate">Registration End Date</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="RegistrationStartDate"
                  v-model="addeventObject.regEndDate"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="Venue">Venue</label>
                <textarea
                  class="form-control form-control-sm"
                  id="Venue"
                  rows="3"
                  maxlength="1000"
                  v-model="addeventObject.venue"
                  required
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label for="MapLink">Map Link</label>
                <textarea
                  class="form-control form-control-sm"
                  id="MapLink"
                  rows="3"
                  maxlength="1000"
                  v-model="addeventObject.mapLink"
                  required
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="DressCode">Dress Code</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="DressCode"
                  maxlength="254"
                  v-model="addeventObject.dressCode"
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="EntryFeeForMember">Entry Fee For Member</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="EntryFeeForMember"
                  v-model="addeventObject.memberEntryFee"
                  required
                />
              </div>
              <div class="form-group col-md-4">
                <label for="EntryFeeForNon-Member"
                  >Entry Fee For Non-Member</label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="EntryFeeForNon-Member"
                  v-model="addeventObject.nonMemberEntryFee"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="ContactPerson">Contact Person</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ContactPerson"
                  maxlength="255"
                  v-model="addeventObject.contactPerson"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="ContactNumber">Contact Number</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ContactNumber"
                  v-model="addeventObject.contactPersonMobileNumber"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label for="InviteTemplate">Invite Template</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="InviteTemplate"
                  maxlength="500"
                  v-model="addeventObject.inviteTemplate"
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="ConfirmationTemplate">Confirmation Template</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ConfirmationTemplate"
                  maxlength="500"
                  v-model="addeventObject.confirmationTemplate"
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="ConfirmationTemplate">Reminder Template</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ConfirmationTemplate"
                  maxlength="500"
                  v-model="addeventObject.reminderTemplate"
                  required
                />
              </div>
              <div class="form-group col-md-3">
                <label for="ResourcesLink">Resources Link</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="ResourcesLink"
                  maxlength="1000"
                  v-model="addeventObject.resources"
                  required
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <button type="submit" class="btn btn-spnm btn-sm ml-auto mr-auto">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "AddEvent",
  props: ["eventInfo", "modalId"],
  components: {},
  data() {
    return {
      eventModes:[],
      addeventObject:{},
      addeventNullObject: {
        eventId: 0,
        title: null,
        tagLine: null,
        shortDescription: null,
        description1: null,
        description2: null,
        description3: null,
        eventDate: null,
        eventTime: null,
        eventMode: 0,
        regStartDate: null,
        regEndDate: null,
        venue: null,
        dressCode: null,
        mapLink: null,
        memberEntryFee: 0,
        nonMemberEntryFee: 0,
        contactPerson: null,
        contactPersonMobileNumber: 0,
        inviteTemplate: "",
        resources: "",
        confirmationTemplate: "",
        reminderTemplate: "",
        active: "Y",
      },
    };
  },
  created() {
    this.getEventModes();

    if(this.eventInfo){
      this.editEvent(this.eventInfo);
    }
    else{
      this.addeventObject = this.addeventNullObject
    }
  },
  mounted() {},
  methods: {
    editEvent(eventInfo){
      
      this.addeventObject ={
        eventId: eventInfo.eventId,
        title: eventInfo.title,
        tagLine: eventInfo.tagLine,
        shortDescription: eventInfo.shortDescription,
        description1: eventInfo.description1,
        description2: eventInfo.description2,
        description3: eventInfo.description3,
        eventDate: moment(eventInfo.eventDate).format('yyyy-MM-DD'),
        eventTime: eventInfo.eventTime,
        eventMode: eventInfo.eventMode,
        regStartDate: moment(eventInfo.regStartDate).format('yyyy-MM-DD'),
        regEndDate: moment(eventInfo.regEndDate).format('yyyy-MM-DD'),
        venue: eventInfo.venue,
        dressCode: eventInfo.dressCode,
        mapLink: eventInfo.mapLink,
        memberEntryFee: eventInfo.memberEntryFee,
        nonMemberEntryFee: eventInfo.nonMemberEntryFee,
        contactPerson: eventInfo.contactPerson,
        contactPersonMobileNumber: eventInfo.contactPersonMobileNumber,
        inviteTemplate: eventInfo.inviteTemplate,
        resources: eventInfo.resources,
        confirmationTemplate: eventInfo.confirmationTemplate,
        reminderTemplate: eventInfo.reminderTemplate,
        active: eventInfo.active,
      }
     
    },
    getEventModes() {
      var _this = this;
      const emptyObj = {
        gpType: "EventMode",
      };
      axios
        .post(
          window.location.origin + "/api/GeneralPurpose/GPByGPType",
          emptyObj,
          {
            withCredentials: true,
          }
        )
        .then(function (resp) {
          _this.eventModes = resp.data.generalPurposeItems;

        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },
    saveEvent() {
      var _this = this;
      this.addeventObject.memberEntryFee = parseInt(this.addeventObject.memberEntryFee);
      this.addeventObject.nonMemberEntryFee = parseInt(this.addeventObject.nonMemberEntryFee);
      this.addeventObject.contactPersonMobileNumber = parseInt(this.addeventObject.contactPersonMobileNumber);
      
      axios
        .post("/api/Event/SaveEvent", this.addeventObject)
        .then(function (response) {
          alert(response.data);
          _this.$emit("recordSave");
          $("#"+_this.modalId).modal("toggle")
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },
    refreshForm(){
      this.addeventNullObject= {
        eventId: 0,
        title: null,
        tagLine: null,
        shortDescription: null,
        description1: null,
        description2: null,
        description3: null,
        eventDate: null,
        eventTime: null,
        eventMode: 0,
        regStartDate: null,
        regEndDate: null,
        venue: null,
        dressCode: null,
        mapLink: null,
        memberEntryFee: 0,
        nonMemberEntryFee: 0,
        contactPerson: null,
        contactPersonMobileNumber: 0,
        inviteTemplate: "",
        resources: "",
        confirmationTemplate: "",
        reminderTemplate: "",
        active: "Y",
      }
    }
  },
};
</script>

<style>
</style>