import $ from "jquery";
import moment from "moment";
export function exportoptions(titleString, filterId) {

  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          // Strip $ from salary column to make it numeric
          return column === 5 ? data.replace(/[$,]/g, "") : data;
        },
      },
    },
  };

  // ----------------------date----------------------
  let time = new Date().toLocaleTimeString();

  let date = new Date().toUTCString().slice(5, 16);

  // ------------------------------------


  var expoter = [
    // $.extend(true, {}, buttonCommon, {
    //   extend: "print",
    //   footer: "true",
    //   title:titleString,
    //   orientation:"landscape",
    //   customize: function (win) {


    //     $(win.document.body)
    //       .find("table")
    //       .addClass("compact")
    //       .css("font-size", "inherit");
    //   },
    // }),
    // {
    //   text: 'Filter <i class="fas fa-filter" style="font-size:0.8rem"></i>',
    //   action: function () {
    //     $("#" + filterId).modal('show');
    //   }
    // },

    {
      extend: "print",
      title: titleString,
      className: "hide-for-mobile",
      filename: titleString + "_" + moment().format('DDMMYYYYhmmss'),
    },
    {
      extend: "excel",
      title: titleString,
      filename: titleString + "_" + moment().format('DDMMYYYYhmmss'),
      //   customize: function ( xlsx ) {
      //     var sheet = xlsx.xl.worksheets['sheet1.xml'];
      //     $('c[r=A1] t', sheet).text(titleString.trim());
      //     // $('row:first c',sheet).attr("s", "2");
      //   }
      customize: function (xlsx) {

        var sSh = xlsx.xl['styles.xml'];
        var lastXfIndex = $('cellXfs xf', sSh).length - 1;

        var n1 = '<numFmt formatCode="##0.0000%" numFmtId="300"/>';
        var s1 = '<xf numFmtId="300" fontId="0" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyNumberFormat="1"/>';
        var s2 = '<xf numFmtId="0" fontId="2" fillId="0" borderId="0" applyFont="1" applyFill="1" applyBorder="1" xfId="0" applyAlignment="1">' +
          '<alignment horizontal="center"/></xf>';
        sSh.childNodes[0].childNodes[0].innerHTML += n1;
        sSh.childNodes[0].childNodes[5].innerHTML += s1 + s2;



        var greyBoldCentered = lastXfIndex + 2;

        var sheet = xlsx.xl.worksheets['sheet1.xml'];

        $('row:eq(0) c', sheet).attr('s', greyBoldCentered);  //grey background bold and centered, as added above


      }
    }

  ]
  return expoter
}