<template>
  <div>
    <div class="card mt-3" style="min-height: 80vh">
      <div id="slideHeading" class="card-header ml-auto mr-auto p-0 mt-3">
        <h5>Contributing Organisations</h5> 
        <span class="borderSection"></span>
      </div>

      <div id="slideLogos" class="card-body p-0" style="height: 100%">
        <div class="row" style="min-height: 70vh">
          <div class="col" style="height: 100%">
            <div class="row mr-0 mt-2" style="width: 100%; text-align: center">
              <div class="col mt-3">
                <img dir="small"
                  class="mt-1 "
                  src="../../../assets/Contributors/renataPNG.webp"
                  alt=""
                  height="90"
                  width="210"
                />
              </div>
            </div>
            <div class="row mr-0 mt-2" style="width: 100%; text-align: center">
              <div class="col mt-3">
                <img dir="small"
                  class="mt-1 "
                  src="../../../assets/Contributors/everlloyed.webp"
                  alt=""
                  height="100"
                  width="130"
                />
              </div>
            </div>
            <div class="row mt-4 mr-0" style="width: 100%; text-align: center">
              <div class="col">
                <img dir="small"
                  class="mt-1 "
                  src="../../../assets/Contributors/nagarkot-logo.webp"
                  alt=""
                  height="100"
                  width="120"
                />
              </div>
            </div>

    
         
         
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slideThree",
};
</script>

<style>
@media only screen and (max-width: 600px) {
  #slideHeading {
    transform: scale(0.5);
  }
  #slideLogos {
    transform: scale(0.5);
  }
}
</style>
