<template>
  <div>
    <div
      class="modal fade"
      id="loginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 1rem">
          <div class="modal-header" style="border-bottom: 0px">
            <h5 class="modal-title text-secondary">
              <i class="fas fa-user-tie"></i> Login
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pl-5 pr-5 pb-5 pt-2 text-left">
            <!-- <div class="row justify-content-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          </div> -->
            <form class="pt-4" @submit.prevent="login()">
              <div class="form-group">
                <label for="loginEmail">Personal Email</label>
                <input
                  type="email"
                  class="form-control form-control-sm"
                  id="loginEmail"
                  v-model="loginObject.LoginId"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div class="form-group">
                <label for="loginEmail">Password</label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  id="loginPassword"
                  v-model="loginObject.Password"
                  placeholder=""
                  required
                />
              </div>
              <div class="form-group row justify-content-center mb-0">
                <button
                  type="submit"
                  class="btn btn-spnm btn-sm"
                  :disabled="loading"
                >
                  Login
                </button>
                <i
                  v-if="loading"
                  class="fas fa-2x fa-sync-alt fa-spin text-secondary ml-2"
                ></i>
              </div>
            </form>

            <div class="row justify-content-center">
              <p class="m-0">
                <small><a  @click="switchToForgotPassword">Forget Password?</a></small>
              </p>
            </div>
            <div class="row justify-content-center">
              <p class="m-0">
                <small
                  >New User?
                  <a @click="switchToRegistration"
                    >Become a Member</a
                  ></small
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MembershipForm />
    <ForgotPassword/>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import MembershipForm from "../Membership/MembershipRegistrationModal.vue";
import ForgotPassword from "./ForgotPassword.vue"
export default {
  name: "Login",
  components: {
    MembershipForm,
    ForgotPassword
  },
  props: [],
  data() {
    return {
      loading: false,
      loginObject: {
        LoginId: "",
        Password: "",
      },
    };
  },
  methods: {
    switchToForgotPassword(){
      $("#loginModal").modal("toggle");
      $("#forgotPasswordModal").modal("toggle");
    },
    switchToRegistration() {
      $("#loginModal").modal("toggle");
      $("#membershipFormModal").modal("toggle");
    },
    login() {
      this.loading = true;
      var _this = this;
      axios
        .post("/api/Auth/Login", this.loginObject)
        .then(function (response) {
          
          location.reload();
        })
        .catch(function (error) {
          console.log(error)
          _this.loading = false;
          alert(error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style>
a {
  cursor: pointer;
}
</style>