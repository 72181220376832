<template>
  <div class="home">
    <Navbar />
    <div id="contentSection" class="" style="min-height: 100vh">
      <div class="row" style="margin: 0px; padding: 0px">
        <div class="col-lg-9" style="padding-left: 0px; margin: 0px; padding: 0px">
          <div id="routerView" class="container-fluid pl-4 mt-5 routerSection">
            <router-view></router-view>
          </div>

          <FooterSections />
        </div>
        <div id="sideBar" class="col-lg d-none d-lg-block d-xl-block h-100">
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Core/Navbar.vue";
import FooterSections from "../components/Core/Footer.vue";
import Sidebar from "../components/Home/Sidebar/main.vue";
import axios from "axios";
import moment from "moment"

export default {
  name: "Core",
  components: {
    Navbar,
    FooterSections,
    Sidebar,
  },
  
  created() {
    this.checkLogin();
    this.getEvents();
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    getEvents() {
      
      var _this = this;
      var requestBody = {};
      axios
        .post("/api/Event/GetUpcomingEventsList", requestBody)
        .then(function (response) {
     
          _this.$store.commit("setUpcomingEvent", response.data.eventCollectionItems);
       
          // _this.identifyRegisteredEvents(_this.allEvents);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },
    checkLogin(){
      var requestBody = {}
      var _this = this
      axios
        .post("/api/Auth/CheckLogin", requestBody)
        .then(function (response) {
          _this.$store.commit('setLoggedUserInfo', response.data)
          _this.getUserEvents(response.data);
        })
        .catch(function (error) {
          if(error.response){
            console.log(error.response)
          }
          else{
            console.log(error)
          }
          
        });
    },
    getUserEvents(userInfo) {
      var _this = this;
      var requestBody = {
        MembershipNo: userInfo.membershipNo,
      };
      axios
        .post("/api/EventRegistration/GetUserEvents", requestBody)
        .then(function (response) {
          response.data.forEach(element => {
            element.bookingDate = moment(element.bookingDate).format("DD-MMM-yyyy");
            element.eventDate = moment(element.eventDate).format("DD-MMM-yyyy");
            element.transactionDetailInfo.transactionDate = moment(element.transactionDetailInfo.transactionDate).format("DD-MMM-yyyy");
          });
 
          _this.$store.commit('setUserEvents', response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    },
  },
};
</script>

<style>
#contentSection {
  padding-top: 110px;
}
.shadow {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

#sideBar {
  min-height: 80vh;
}

#routerView {
  min-height: 60vh;
}

@media only screen and (max-width: 600px) {
  /* #sideBar {
    min-height: 0vh;
  } */
}
</style>
