<template>
  <div class="mt-4 text-left" id="change-password">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <form @submit.prevent="changePassword">
          <div class="form-group">
            <label for="newPassword">Old Password</label>
            <input
              type="password"
              class="form-control form-control-sm"
              id="oldPassword"
              aria-describedby="oldPassword"
             
              v-model="changePasswordObject.oldPassword"
              required
            />
          </div>
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input
              type="password"
              class="form-control form-control-sm"
              id="newPassword"
              aria-describedby="emailHelp"
              
              v-model="changePasswordObject.password1"
              required
            />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              class="form-control form-control-sm"
              id="confirmPassword"
              
              v-model="changePasswordObject.password2"
              required
            />
          </div>

          <button type="submit" class="btn btn-spnm btn-sm">Submit</button>
        </form>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ChangePassword",
  props: ["UserInfo"],
  data() {
    return {
      userInfo: null,
      changePasswordObject: {
        oldPassword:null,
        password1: null,
        password2: null,
      },
    };
  },
  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData;
      if (this.userInfo) {
        this.userInfo.membershipValidTill = moment(
          this.userInfo.membershipValidTill
        ).format("DD-MMM-yyyy");
      }
    },
  },

  computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
  },
  created() {
    this.userInfo = this.$store.getters.LoggedUserInfo;
  },
  mounted() {},
  methods: {
    changePassword() {
      if (
        this.changePasswordObject.password1 !=
        this.changePasswordObject.password2
      ) {
        alert("Password fields are not matching.Please Retry.");
        return;
      }

      this.userInfo.encryptedPassword = this.changePasswordObject.password1;

      var _this = this;
      axios
        .post("/api/Auth/ChangePassword", this.changePasswordObject)
        .then(function (response) {
          // handle succes
          alert(response.data);
          _this.$router.push('/users/eduardo')
          location.reload();
        })
        .catch(function (error) {
          // handle error

          if (error.response) {
            alert(error.response.data);
          } else {
            console.log(error);
          }
        });
    },
  },
};
</script>

<style>
</style>