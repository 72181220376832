import { createStore } from 'vuex'
import LoggedUserInfo from "./modules/LoggedInUserInfo"
import UserEvents from "./modules/UserEvents"
import IndustryVerticals from "./modules/IndustryVerticals"
import UpcomingEvent from "./modules/UpcomingEvents"
import IsAdmin from "./modules/IsAdmin"
import LoadingCounter from './modules/LoadingCounter'


export default createStore({
  modules:{
    LoggedUserInfo,
    UserEvents,
    IndustryVerticals,
    UpcomingEvent,
    IsAdmin,
    LoadingCounter
}
})
