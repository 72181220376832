<template>
  <div
    class="modal fade text-left"
    id="membershipFormModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="membershipFormModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="membershipFormModalLabel">
            Register For Membership
          </h6>
          <button
            @click="resetModal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body custom-scroller">
          <!-- <MembershipForm @proceed="switchModalScreen"/> -->
          <component
            :is="dynamicModalId"
            @proceed="switchModalScreen"
            @reset="resetModal"
            ref="MembershipForm"
            :referenceData="modalReferenceData"
          >
          </component>
          <div
            v-if="registrationSuccess"
            class="alert alert-success"
            role="alert"
            style="text-align: center"
          >
            <i class="fas fa-circle-check"></i> Application Sent Successfully!
            Our coordinator will be get in touch with you soon.
          </div>

          <div
            v-if="registrationError"
            class="alert alert-danger"
            role="alert"
            style="text-align: center"
          >
            <i class="fas fa-circle-xmark"></i> Please Re-try. For any query,
            Please mail us at mumbai@shippingnetwork.co.uk
          </div>
          <!-- <div class="overlay dark">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import MembershipForm from "./MembershipForm.vue";
import MemershipSummaryDetails from "./MembershipSummary.vue";
import PaymentSuccess from "../PaymentGateway/PaymentSuccess.vue";
import $ from 'jquery'
export default {
  name: "MembershipRegistration ",
  props: ["referenceData"],
  components: {
    MembershipForm,
    MemershipSummaryDetails,
    PaymentSuccess,
  },
  data() {
    return {
      dynamicModalId: "MembershipForm",
      modalReferenceData: null,
      registrationSuccess: false,
      registrationError: false,
      membershipBody: {
        firstName: null,
        lastName: null,
        personalEmail: null,
        officialEmail: null,
        gender: null,
        birthDate: null,
        highestEducation: null,
        companyName: null,
        preferedAddress: null,
        jobTitle: null,
        industryVertical: null,
        otherIndustryVertical: null,
        personalMobileNumber: null,
        alternativeMobileNumber: null,
      },

      membershipForm: {
        firstName: null,
        lastName: null,
        personalEmail: null,
        officialEmail: null,
        gender: null,
        birthDate: null,
        highestEducation: null,
        companyName: null,
        preferedAddress: null,
        jobTitle: null,
        industryVertical: null,
        otherIndustryVertical: null,
        personalMobileNumber: null,
        alternativeMobileNumber: null,
      },
    };
  },

  mounted() {
    this.modalReferenceData = this.referenceData;
  },

  methods: {
    switchModalScreen(componentName, referenceData) {
      this.dynamicModalId = componentName;
      this.modalReferenceData = referenceData;

      if (componentName == "PaymentSuccess") {
        this.$emit("refreshList");
        this.resetModal();
      }
    },
    resetModal() {
      this.dynamicModalId = "MembershipForm";
      this.registrationSuccess = false;
      this.registrationError = false;
      if (this.$store.getters.IsAdmin) {
        $("#membershipFormModal").modal("toggle");
      }
    },
  },
};
</script>

<style>
.overlay {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2051;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>