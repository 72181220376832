<template>
  <img dir="small"
    v-if="launchAnimation"
    class="demo-bg"
    src="../assets/blast.webp"
    alt=""
  />
  <div class="row h-100 w-100 launchPage demo-content">
    <div class="col align-self-center">
      <h1 class="spnmName">spnm.in</h1>
      <h5 v-if="!launchAnimation" class="m-3">
        Please Enter Launch Code To Launch
      </h5>
      <input
        v-if="!launchAnimation"
        type="password"
        v-model="launchCode"
        class="form-control ml-auto mr-auto"
        id="exampleInputEmail1"
        style="max-width: 100px"
      />
      <button
        v-if="!launchAnimation"
        @click="checkLaunchCode()"
        type="button"
        class="btn launchButton m-2"
      >
        <b>Launch</b></button
      ><br />
      <div
        v-if="launchAnimation"
        class="spinner-border text-danger m-3"
        role="status"
      >
        <span class="sr-only">Launching...</span>
      </div>
      <h6 v-if="launchAnimation" style="color: grey">Launching...</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "LaunchPage",

  data() {
    return {
      launchCode: null,
      launchAnimation: false,
    };
  },

  mounted() {},

  methods: {
    checkLaunchCode() {
    
      if (this.launchCode == "9011") {
        this.launchAnimation = true;
        var _this = this;
        setTimeout(launchWebsite, 5000);

        function launchWebsite() {
          _this.launchAnimation = false;
          _this.$emit("onChange", "correctCode");
        }
      }
    },
  },
};
</script>

<style scoped>
.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.demo-content {
  position: relative;
}
.spnmName {
  font-size: 200px;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgba(59, 90, 167, 1) 21%,
    rgba(236, 57, 95, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.launchButton {
  background: linear-gradient(
    120deg,
    rgba(59, 90, 167, 1) 21%,
    rgba(236, 57, 95, 1) 100%
  );
  color: #fff;
}
</style>