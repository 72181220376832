<template>
  <div>
    <div
      class="modal fade"
      id="forgotPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="forgotPasswordModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 1rem">
          <div class="modal-header" style="border-bottom: 0px">
            <h5 class="modal-title text-secondary">
              <i class="fas fa-key"></i> Reset Password
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pl-5 pr-5 pb-5 pt-2 text-left">
            <!-- <div class="row justify-content-end">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div> -->
            <form class="pt-4" @submit.prevent="resetPassword()">
              <div class="form-group">
                <label for="loginEmail">Registered Personal Email ID</label>
                <input
                  type="email"
                  class="form-control form-control-sm"
                  id="loginEmail"
                  v-model="loginObject.LoginId"
                  placeholder="name@example.com"
                  required
                />
              </div>
              <div class="form-group row justify-content-start mb-0">
                <button
                  type="button"
                  class="btn btn-spnm btn-sm ml-3"
                  @click="sendOTP()"
                  :disabled="loading"
                >
                  <span v-if="!otpSent">Generate Code</span> <span v-if="otpSent">Re-Generate Code</span>
                </button>
                <i
                  v-if="loading"
                  class="fas fa-2x fa-sync-alt fa-spin text-secondary ml-2"
                ></i>
              </div>
              <div class="form-group mt-2">
                <label for="loginEmail">Enter Code Sent On Email Id</label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  id="loginPassword"
                  v-model="loginObject.OTP"
                  placeholder=""
                  required
                  :disabled="!otpSent"
                />
              </div>
              <div class="form-group">
                <label for="loginEmail">New Password</label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  id="loginPassword"
                  v-model="loginObject.Password"
                  placeholder=""
                  required
                  :disabled="!otpSent"
                />
              </div>
              <div class="form-group row justify-content-center mb-0">
                <button
                  type="submit"
                  class="btn btn-spnm btn-sm"
                  :disabled="loading ||!otpSent "
                >
                  Change Password
                </button>
                <i
                  v-if="loading"
                  class="fas fa-2x fa-sync-alt fa-spin text-secondary ml-2"
                ></i>
              </div>
            </form>

            <div v-if="otpSent" class="row justify-content-center mt-2">
              <div class="alert alert-success alert-sm" role="alert">
                Code Sent on your Personal Email ID.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MembershipForm />
  </div>
</template>
  
  <script>
import axios from "axios";
import $ from "jquery";
import MembershipForm from "../Membership/MembershipRegistrationModal.vue";
export default {
  name: "ForgotPassword",
  components: {
    MembershipForm,
  },
  props: [],
  data() {
    return {
      loading: false,
      otpSent: false,
      loginObject: {
        LoginId: "",
        Password: "",
        OTP:""
      },
    };
  },
  methods: {
    switchToLogin() {
        $("#forgotPasswordModal").modal("toggle");
        $("#loginModal").modal("toggle");
      
    },
    switchToRegistration() {
      $("#forgotPasswordModal").modal("toggle");
      $("#membershipFormModal").modal("toggle");
    },
    sendOTP() {
     
      this.loading = true;
      var _this = this;
      axios
        .post("/api/Auth/SendOTP", this.loginObject)
        .then(function (response) {
          _this.otpSent = true;
          _this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
          alert(error.response.data);
          console.log(error.response.data);
        });
    },
    resetPassword() {
     
      this.loading = true;
      var _this = this;
      axios
        .post("/api/Auth/ResetPassword", this.loginObject)
        .then(function (response) {
          _this.otpSent = true;
          _this.loading = false;
          _this.switchToLogin();
          alert(response.data)
        })
        .catch(function (error) {
          console.log(error);
          _this.loading = false;
          alert(error.response.data);
          console.log(error.response.data);
        });
    },
  },
};
</script>
  
  <style>
a {
  cursor: pointer;
}
</style>