// import axios from "axios";
// import * as access from "../../MegaScript/getaccess";

const state = {

    IndustryVerticals: null
};

const getters = {

    IndustryVerticals: (state) => state.IndustryVerticals
};

const actions = {


};

const mutations = {
    setIndustryVerticals: (state, IndustryVerticals) => (state.IndustryVerticals = IndustryVerticals)
};

export default {
    state,
    getters,
    actions,
    mutations,
}