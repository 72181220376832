<template>
  <div id="user-list" class="">
    <div class="row justify-content-center">
      <h5 class="ml-auto mr-auto">Mailing List</h5>
      <!-- <button
          type="button"
          class="btn btn-light btn-sm"
          data-toggle="modal"
          data-target="#membershipFormModal"
        >
          <i class="fas fa-add"></i>
        </button> -->
    </div>
    <hr />

    <CoreDatatable
      v-if="tableData.tableHeaders != null"
      :dataBase="tableData.dataBase"
      :tableHeaders="tableData.tableHeaders"
      :title="tableData.title"
      :dynamicTableId="dataTableID"
      @onTableReady="enableDatatableFeatures"
    />

    <div v-if="loading" class="overlay p-5">
      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>

    <div class="row justify-content-center">
      <button
        type="button"
        class="btn btn-spnm btn-sm"
        :disabled="selectedUsers.length == 0"
        data-toggle="modal"
        data-target="#emailTemplateListing"
      >
        <i class="emailTemplateListingLabel"></i> Send Mail
      </button>
      <button
        type="button"
        class="btn btn-danger btn-sm ml-2"
        :disabled="selectedUsers.length == 0"
      >
        <i class="fas fa-trash-can"></i> Delete
      </button>
    </div>

    <!-- User Info Modal -->
    <UserInfoModal :userData="selectedUser" />
    <MembershipRegistration @refreshList="getDataBase" />
    <EmailTemplateList :mailingList="selectedUsers"/>
  </div>
</template>
  
  <script>
import { generate } from "@vue/compiler-core";
import $ from "jquery";
import axios from "axios";
import CoreDatatable from "../Core/Datatable.vue";
import UserInfoModal from "../Admin/UserInfoModal.vue";
import MembershipRegistration from "../Membership/MembershipRegistrationModal.vue";
import EmailTemplateList from "../MailingList/EmailTemplateListPopup.vue";
var table;
var databaseLength = 0;

export default {
  name: "MailingList",
  components: {
    CoreDatatable,
    UserInfoModal,
    MembershipRegistration,
    EmailTemplateList,
  },
  data() {
    return {
      dataTableID: "UserListTable",
      selectedUsers: [],
      userInfo: null,
      selectedUser: null,
      loading: true,
      tableData: {
        dataBase: [],
        tableHeaders: null,
        title: "Mailing List",
      },
    };
  },
  created() {},
  mounted() {
    this.getDataBase();
  },
  methods: {
    getDataBase() {
      var requestBody = {};
      var _this = this;
      this.loading = true;
      axios
        .post("/api/MailingListSource/GetList", requestBody)
        .then(function (response) {
          _this.generateTable(response.data);
          databaseLength = response.data.length;
          _this.loading = false;
        })
        .catch(function (error) {
          _this.loading = false;
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },

    generateTable(dataBase) {
      var tableHeaders = [
        {
          title:
            '<input type="checkbox" class=" mt-0 ml-0 select-all" id="selectAll"> ',
          data: "userId",
          className: "dt-center selectAllTHeading",
          orderable: false,
          render: function () {
            return '<input type="checkbox" class=" ml-2 user-select" id="selectCheckbox">';
          },
        },

        {
          title: "First Name",
          className: "dt-left",
          data: "firstName",
        },
        {
          title: "Last Name",
          className: "dt-left",
          data: "lastName",
        },
        {
          title: "Company Name",
          className: "dt-left",
          data: "companyName",
        },
        {
          title: "Job Title",
          className: "dt-left",
          data: "jobTitle",
        },
        {
          title: "Personal Mail ID",
          className: "dt-left",
          data: "personalEmailId",
        },
        {
          title: "Contact Number",
          className: "dt-left",
          data: "personalMobileNo",
        },
      ];

      this.tableData.tableHeaders = tableHeaders;
      this.tableData.dataBase = dataBase;
    },

    enableDatatableFeatures(database) {
      table = $("#dashboardTable" + this.dataTableID).DataTable();

      var _this = this;
      $("#dashboardTable" + this.dataTableID + " tbody").on(
        "click",
        "input.user-select",

        function () {
          var rowdata = table.row($(this).parents("tr")).data();
          let obj = _this.selectedUsers.find(
            (o) => o.userId === rowdata.userId
          );

          if (obj) {
            _this.selectedUsers.splice(
              _this.selectedUsers.findIndex((a) => a.userId === rowdata.userId),
              1
            );
          } else {
            _this.selectedUsers.push(rowdata);
          }

          if (_this.selectedUsers.length == databaseLength) {
            _this.selectedUsers = _this.tableData.dataBase;
            $(".select-all").prop("checked", true);
          } else {
            $(".select-all").prop("checked", false);
          }
        }
      );

      $("#dashboardTable" + this.dataTableID + " tbody").on(
        "click",
        "span.userInfo",

        function () {
          var rowdata = table.row($(this).parents("tr")).data();
          _this.selectedUser = rowdata;
          $("#userInfoModal").modal("toggle");
        }
      );

      $("#dashboardTable" + this.dataTableID + " thead").on(
        "click",
        "input.select-all",

        function () {
          if (_this.selectedUsers.length != parseInt(databaseLength)) {
            _this.selectedUsers = _this.tableData.dataBase;
            $(".user-select").prop("checked", true);
          } else {
            _this.selectedUsers = [];
            $(".user-select").prop("checked", false);
          }
        }
      );
    },

    sendCredentials() {
      var requestBody = {
        UserCollectionItems: this.selectedUsers,
      };
      var _this = this;
      axios
        .post("/api/Membership/SendCredentials", requestBody)
        .then(function (response) {
          alert(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log(error);
          }
        });
    },

    deleteUser() {
      var requestBody = {
        UserPKIds: "",
      };

      this.selectedUsers.forEach((element) => {
        if (requestBody.UserPKIds.length == 0) {
          requestBody.UserPKIds = element.userId.toString();
        } else {
          requestBody.UserPKIds =
            requestBody.UserPKIds + "," + element.userId.toString();
        }
      });

      if (confirm("Are you sure to delete selected users ?")) {
      } else {
        return;
      }

      var _this = this;

      this.loading = true;

      axios
        .post("/api/User/DeleteUsers", requestBody)
        .then(function (response) {
          _this.getDataBase();
          _this.loading = false;
          alert(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          } else {
            console, log(error);
          }
        });
    },
  },
};
</script>
  
  <style>
.selectAllTHeading {
  padding-bottom: 5.5rem !important;
}
</style>