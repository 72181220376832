<template>
  <div class="modal" id="userInfoModal" tabindex="-1" role="dialog" aria-labelledby="userInfoModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="userInfoModalLabel"><span v-if="userDetails">{{userDetails.firstName}} {{userDetails.lastName}} <small>({{userDetails.jobTitle}})</small></span></h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <Profile v-if="userDetails" :userDetails="userDetails" />
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
import Profile from "../../views/Profile.vue"
export default {
    name:"UserInfoModal",
    props:['userData'],
    components:{
        Profile
    },
    data() {
        return {
            userDetails:null
        }
    },

    watch:{
        userData:function (newVal,oldVal) {
            this.userDetails = newVal;
            
        }
    },
    
    created() {
        this.userDetails = this.userData
    
    },
    mounted() {
        
    },

}
</script>

<style>

</style>