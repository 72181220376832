
const state = {
    LoadingCounter: 0,
}

const getters = {
    LoadingCounter: (state) => state.LoadingCounter
}

const actions = {
    async increaseLoadingCounter({ commit }) {
        commit(
            "setIncLoadingCounter",
            1
        );
    },
    async decreaseLoadingCounter({ commit }) {
        commit(
            "setDecLoadingCounter",
            1
        );
    },
}

const mutations = {
    setIncLoadingCounter: (state, LoadingCounter) => (state.LoadingCounter = state.LoadingCounter + LoadingCounter),
    setDecLoadingCounter: (state, LoadingCounter) => (state.LoadingCounter = state.LoadingCounter - LoadingCounter)
}

export default {
    state,
    getters,
    actions,
    mutations,
}