// import axios from "axios";
// import * as access from "../../MegaScript/getaccess";

const state = {

    UpcomingEvent: null
};

const getters = {

    UpcomingEvent: (state) => state.UpcomingEvent
};

const actions = {


};

const mutations = {
    setUpcomingEvent: (state, UpcomingEvent) => (state.UpcomingEvent = UpcomingEvent)
};

export default {
    state,
    getters,
    actions,
    mutations,
}