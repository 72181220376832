<template>
  <div>
    <form class="text-left" @submit.prevent="generateOrder()">
      <div class="form-row text-left">
        <div class="form-group form-group-sm col-md-6">
          <label for="firstName">First Name</label>
          <input
            v-model="membershipBody.firstName"
            type="text"
            class="form-control form-control-sm"
            id="firstName"
            maxlength="50"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="lastName">Last Name</label>
          <input
            v-model="membershipBody.lastName"
            type="text"
            class="form-control form-control-sm"
            id="lastName"
            maxlength="50"
            required
          />
        </div>
      </div>
      <!-- <div class="form-row">
              <div class="form-group col-md-6">
                <label for="username">Username</label>
                <input v-model="membershipBody.userName" type="text" class="form-control" id="username" />
              </div>
              <div class="form-group col-md-6">
                <label for="password">Password</label>
                <input v-model="membershipBody.password" type="password" class="form-control" id="password" />
              </div>
            </div> -->
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="personalEmail">Personal Email</label>
          <input
            v-model="membershipBody.personalEmailId"
            type="email"
            class="form-control form-control-sm"
            id="personalEmail"
            maxlength="254"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="officalEmail">Official Email</label>
          <input
            v-model="membershipBody.officialEmailId"
            type="email"
            class="form-control form-control-sm"
            id="officalEmail"
            maxlength="254"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="gender">Gender</label>
          <select
            v-model="membershipBody.gender"
            id="gender"
            class="form-control form-control-sm"
            maxlength="50"
            required
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="birthDate">Birth Date </label>
          <input
            v-model="membershipBody.birthDate"
            type="date"
            class="form-control form-control-sm"
            id="birthDate"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="education">Highest Education</label>
          <input
            v-model="membershipBody.highestEducation"
            type="text"
            class="form-control form-control-sm"
            id="education"
            maxlength="254"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="membershipPaln">Select Membership Plan</label>
          <select
            v-model="membershipBody.membershipPlan"
            id="membershipPaln"
            class="form-control form-control-sm"
            :disabled="membershipPlans.length == 1"
            required
          >
            <option
              v-for="plan in membershipPlans"
              :key="plan.gpId"
              :value="plan.gpId"
            >
              {{ plan.shortDescription }}(Rs.{{ plan.field1 }})
            </option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="industryVertical">Industry/Vertical</label>
          <select
            v-model="membershipBody.industryVertical"
            @click="handleIndustryVerticalChange"
            class="form-control form-control-sm"
            id="industryVertical"
            aria-label="industryVertical"
            required
          >
            <option
              v-for="vertical in industryVerticals"
              :key="vertical.gpId"
              :value="vertical.gpId"
            >
              {{ vertical.shortDescription }}
            </option>
            <option value="0">Others</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="personalMobileNumber"
            >Enter Your Industry/Vertical:</label
          >
          <input
            :disabled="membershipBody.industryVertical != '0'"
            v-model="membershipBody.otherIndustryVertical"
            type="text"
            class="form-control form-control-sm"
            id="personalMobileNumber"
            maxlength="249"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="companyName">Company Name</label>
          <input
            v-model="membershipBody.companyName"
            type="text"
            class="form-control form-control-sm"
            id="companyName"
            maxlength="254"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="jobTitle">Job Title/Position</label>
          <input
            v-model="membershipBody.jobTitle"
            type="text"
            class="form-control form-control-sm"
            id="jobTitle"
            maxlength="254"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="personalMobileNumber">Personal Mobile No.</label>
          <input
            v-model="membershipBody.personalMobileNo"
            type="text"
            class="form-control form-control-sm"
            id="personalMobileNumber"
            maxlength="13"
            required
          />
        </div>
        <div class="form-group col-md-6">
          <label for="alternativeMobileNumber">Alternative Mobile No.</label>
          <input
            v-model="membershipBody.alternativeMobileNo"
            type="text"
            class="form-control form-control-sm"
            id="alternativeMobileNumber"
            maxlength="13"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md">
          <label for="address">Preffered Address</label>
          <textarea
            v-model="membershipBody.address"
            class="form-control form-control-sm"
            id="address"
            rows="2"
            maxlength="999"
            required
          ></textarea>
        </div>
      </div>

      <div class="form-row mb-3 justify-content-center">
        <button type="submit" class="btn btn-spnm btn-sm">Proceed</button>
      </div>
    </form>
    <div v-if="loading" class="overlay">
      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "MembershipForm",
  data() {
    return {
      industryVerticals: [],
      membershipPlans: [],
      userInfo: null,
      loading: false,
      membershipBody: {
        firstName: null,
        lastName: null,
        personalEmailId: null,
        officialEmailId: null,
        userType: 0,
        gender: null,
        birthDate: null,
        highestEducation: null,
        industryVertical: null,
        otherIndustryVertical: null,
        companyName: null,
        jobTitle: null,
        personalMobileNo: null,
        alternativeMobileNo: null,
        address: null,
        membershipPlan: 0,
        active: "Y",
      },
    };
  },
  watch: {
    watchUserInfo: function (newData, oldData) {
      this.userInfo = newData;

      if (this.userInfo != null) {
        if (!this.$store.getters.IsAdmin) {
          this.membershipBody = this.userInfo;
          this.membershipBody.birthDate = moment(
            this.membershipBody.birthDate
          ).format("yyyy-MM-DD");
        }
      }
    },
  },
  computed: {
    watchUserInfo() {
      return this.$store.getters.LoggedUserInfo;
    },
  },

  created() {
    this.getIndustryVertical();
    this.getMembershipPlans();

    console.log();

    this.userInfo = this.$store.getters.LoggedUserInfo;

    if (this.userInfo != null && this.userInfo.membershipStatus == "InActive") {
      if (!this.$store.getters.IsAdmin) {
        this.membershipBody = this.userInfo;
        this.membershipBody.birthDate = moment(
          this.membershipBody.birthDate
        ).format("yyyy-MM-DD");
      }
    }
  },

  methods: {
    
    resetForm() {
      if (this.$store.getters.LoggedUserInfo) {
        return null;
      }
      this.membershipBody = {
        firstName: null,
        lastName: null,
        personalEmailId: null,
        officialEmailId: null,
        userType: 0,
        gender: null,
        birthDate: null,
        highestEducation: null,
        industryVertical: null,
        otherIndustryVertical: null,
        companyName: null,
        jobTitle: null,
        personalMobileNo: null,
        alternativeMobileNo: null,
        address: null,
        membershipPlan: 0,
        active: "Y",
      };
    },
    generateOrder() {
      var _this = this;

      var bodyObject = this.membershipBody;

      if (bodyObject.userId!=0) {
        bodyObject.membershipValidFrom = moment(bodyObject.membershipValidFrom).format('yyyy-MM-DD')
        bodyObject.membershipValidTill = moment(bodyObject.membershipValidTill).format('yyyy-MM-DD')
      }
      bodyObject.industryVertical = parseInt(bodyObject.industryVertical)

      this.loading = true;

      // Make a request for a user with a given ID
      axios
        .post("/api/Membership/Order", bodyObject)
        .then(function (response) {
          _this.loading = false;
          _this.$emit("proceed", "MemershipSummaryDetails", response.data);
        })
        .catch(function (error) {
          _this.loading = false;
          if (error.response) {
            alert(error.response.data[0]);
          } else {
            console.log(error);
          }
        });
    },

    getIndustryVertical() {
      var _this = this;
      const emptyObj = {
        gpType: "IndustryVertical",
      };
      axios
        .post(
          window.location.origin + "/api/GeneralPurpose/GPByGPType",
          emptyObj,
          {
            withCredentials: true,
          }
        )
        .then(function (resp) {
          _this.industryVerticals = resp.data.generalPurposeItems;
          _this.$store.commit(
            "setIndustryVerticals",
            resp.data.generalPurposeItems
          );
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },

    getMembershipPlans() {
      var _this = this;
      const emptyObj = {
        gpType: "MembershipType",
      };
      axios
        .post(
          window.location.origin + "/api/GeneralPurpose/GPByGPType",
          emptyObj,
          {
            withCredentials: true,
          }
        )
        .then(function (resp) {
          _this.membershipPlans = resp.data.generalPurposeItems;
          if (_this.membershipPlans.length == 1) {
            _this.membershipBody.membershipPlan = _this.membershipPlans[0].gpId;
          }
        })
        .catch(function (error) {
          if (error.response) {
            alert("Something went wrong.");
          } else {
            console.log(error);
          }
        });
    },

    handleIndustryVerticalChange() {
      this.membershipBody.otherIndustryVertical = "";
    },
  },
};
</script>

<style>
</style>