// import axios from "axios";
// import * as access from "../../MegaScript/getaccess";

const state = {

    LoggedUserInfo: null
};

const getters = {

    LoggedUserInfo: (state) => state.LoggedUserInfo
};

const actions = {


};

const mutations = {
    setLoggedUserInfo: (state, LoggedUserInfo) => (state.LoggedUserInfo = LoggedUserInfo)
};

export default {
    state,
    getters,
    actions,
    mutations,
}