import { createRouter, createWebHashHistory  } from 'vue-router'
// import Home from '../views/Home.vue'
// import Board from '../views/about/board.vue'
// import Members from '../views/about/members.vue'
// import Events from '../views/Events.vue'
// import Media from "../views/Media.vue"
// import Membership from "../views/membership.vue"
// import Organisations from "../views/Contributors/organisations.vue"
// import Partners from "../views/Contributors/partners.vue"
// import Sponsors from "../views/Contributors/sponsor.vue"
// import Affiliates from "../views/affiliates.vue"
// import Profile from "../views/Profile.vue"
// import Admin from "../views/Admin.vue"
// import Desclaimer from "../views/Legal/Desclaimer.vue"
// import PrivacyPolicy from "../views/Legal/PrivacyPolicy.vue"
// import TermsAndConditions from "../views/Legal/TermsAndConditions.vue"
// import About from "../views/about/about.vue"
const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home,
    component: () => import('../views/Home.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // component: Admin,
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    // component: Profile,
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/members/board',
    name: 'Board',
    // component: Board,
    component: () => import('../views/about/board.vue')
  },
  {
    path: '/members/supporting',
    name: 'Members',
    // component: Members,
    component: () => import('../views/membership.vue')
  },
  
  {
    path: '/about',
    name: 'About',
    // component: About
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/about.vue')
  },
  {
    path: '/events/:eventType',
    name: 'Events',
    // component: Events,
    component: () => import('../views/Events.vue')
  },
  {
    path: '/media/:mediaType',
    name: 'Media',
    // component: Media,
    component: () => import('../views/Media.vue')
  },
  {
    path: '/membership',
    name: 'Membership',
    // component: Membership,
    component: () => import('../views/membership.vue')
  },
  {
    path: '/contributors-organisations',
    name: 'Organisations',
    // component: Organisations,
    component: () => import('../views/Contributors/organisations.vue')
  },
  {
    path: '/contributors-partners',
    name: 'Partners',
    // component: Partners,
    component: () => import('../views/Contributors/partners.vue')
  },
  {
    path: '/contributors-sponsors',
    name: 'Sponsors',
    // component: Sponsors,
    component: () => import('../views/Contributors/sponsor.vue')
  },
  {
    path: '/affiliates',
    name: 'Affiliates',
    // component: Affiliates,
    component: () => import('../views/affiliates.vue')
  },
  {
    path: '/Desclaimer',
    name: 'Desclaimer',
    // component: Desclaimer,
    component: () => import('../views/Legal/Desclaimer.vue')
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    // component: PrivacyPolicy,
    component: () => import('../views/Legal/PrivacyPolicy.vue')
  },
  {
    path: '/TermsAndConditions',
    name: 'TermsAndConditions',
    // component: TermsAndConditions,
    component: () => import('../views/Legal/TermsAndConditions.vue')
  }
  

]

const router = createRouter({
  history: createWebHashHistory (process.env.BASE_URL),
  routes
})

export default router
